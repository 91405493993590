import React from 'react';

import { Accordion, SENTRY_UNMASK, useTheme } from '@almond/ui';
import { cypressUtilities, useToggle } from '@almond/utils';
import { Link, useGlobalSearchParams, useSegments } from 'expo-router';
import { useGate } from 'statsig-react';
import { useChatContext } from 'stream-chat-react';

import { useAlmondApiQuery } from '~/modules/api';
import { EditDemographicModal, EditRightAction } from '~/modules/profile';
import { useAdminSidebar } from '~/modules/routing';
import { AccordionTitle, Sidebar, SidebarButton } from '~/modules/ui';
import { getChannelPatient, useBookUrl, useCurrentUser } from '~/modules/user';

import { useListenForNewAttachments, useToggleMember } from '../../hooks';
import { ConversationMembers } from '../ConversationMembers';
import { HealthRecords } from '../HealthRecords';
import { PatientInformation } from '../PatientInformation';
import { PatientVisits } from '../PatientVisits';
import { SharedMedia } from '../SharedMedia';

import { themedStyles } from './styles';

import type { ConversationDetailsParams, ElationDetailsParams } from '~types';

export const AdminSidebar = () => {
  const { value: isTodosEnabled } = useGate('todos');
  const [styles] = useTheme(themedStyles);
  const searchParams = useGlobalSearchParams<ElationDetailsParams & ConversationDetailsParams>();
  const urlSegments: string[] = useSegments();
  const { channel, client } = useChatContext();
  const currentUserRole = client.user?.role;
  const patientUuid = getChannelPatient(channel)?.patient_uuid as string | undefined;
  const { data, error, mutate } = useAlmondApiQuery(
    patientUuid ? `/patients/${patientUuid as '{patient_uuid}'}/` : null
  );
  const bookUrl = useBookUrl({ patientUuid });
  const labBookUrl = useBookUrl({ patientUuid }, 'lab');
  const currentUser = useCurrentUser();
  const { buttonTitle, buttonType, toggleMember } = useToggleMember({
    user: { id: currentUser?.streamId, role: currentUserRole },
    channel,
    streamId: currentUser?.streamId,
    joinTitle: 'Join Conversation',
    leaveTitle: 'Leave Conversation',
  });
  const [isVisible, toggleIsVisible] = useToggle(false);
  const { isSidebarOpen } = useAdminSidebar();

  useListenForNewAttachments();

  return (
    <Sidebar
      isOpen={isSidebarOpen}
      footer={
        buttonTitle && (
          <SidebarButton type={buttonType} onPress={toggleMember}>
            {buttonTitle}
          </SidebarButton>
        )
      }
    >
      <Accordion
        initiallyOpen
        title={<AccordionTitle>{'Patient Information'}</AccordionTitle>}
        rightAction={<EditRightAction onPress={toggleIsVisible} />}
        style={styles.accordion}
      >
        <PatientInformation canViewElation data={data} error={error} />
      </Accordion>
      <Accordion title={<AccordionTitle>{'Book'}</AccordionTitle>} style={styles.accordion}>
        {data && (
          <>
            <SidebarButton
              style={[styles.button, SENTRY_UNMASK]}
              href={bookUrl}
              // Cypress does not support opening a new tab.
              hrefAttrs={cypressUtilities.isCypressRunning() ? undefined : { target: '_blank' }}
            >
              {'Book Visit'}
            </SidebarButton>
            <SidebarButton
              style={[styles.button, SENTRY_UNMASK]}
              href={labBookUrl}
              // Cypress does not support opening a new tab.
              hrefAttrs={cypressUtilities.isCypressRunning() ? undefined : { target: '_blank' }}
              type="secondary"
            >
              {'Book Lab Visit'}
            </SidebarButton>
          </>
        )}
      </Accordion>
      {isTodosEnabled && (
        <Accordion title={<AccordionTitle>{'Message'}</AccordionTitle>} style={styles.accordion}>
          <Link
            href={
              // TODO make URL relative
              urlSegments.includes('[elationId]')
                ? {
                    pathname: '/(admin)/elation/[elationId]',
                    params: { elationId: searchParams.elationId },
                  }
                : {
                    pathname: '/(admin)/(conversations)/[conversationId]',
                    params: { conversationId: searchParams.conversationId },
                  }
            }
            // @ts-ignore
            asChild
          >
            <SidebarButton style={styles.button}>
              {`Message ${data?.profile.preferredFirstName || data?.profile.firstName}`}
            </SidebarButton>
          </Link>
        </Accordion>
      )}
      <Accordion title={<AccordionTitle>{'Patient Visits'}</AccordionTitle>} style={styles.accordion}>
        <PatientVisits revalidateOnFocus />
      </Accordion>
      {isTodosEnabled && (
        <Accordion title={<AccordionTitle>{'To Do List'}</AccordionTitle>} style={styles.accordion}>
          <Link
            href={
              // TODO make URL relative
              urlSegments.includes('[elationId]')
                ? {
                    pathname: '/(admin)/elation/[elationId]/todos',
                    params: { elationId: searchParams.elationId },
                  }
                : {
                    pathname: '/(admin)/(conversations)/[conversationId]/todos',
                    params: { conversationId: searchParams.conversationId },
                  }
            }
            // @ts-ignore
            asChild
          >
            <SidebarButton style={styles.button}>{`Open To Do List`}</SidebarButton>
          </Link>
        </Accordion>
      )}
      <Accordion title={<AccordionTitle>{'Conversation Members'}</AccordionTitle>} style={styles.accordion}>
        <ConversationMembers canInviteUsers />
      </Accordion>
      <Accordion title={<AccordionTitle>Health Records</AccordionTitle>} style={styles.accordion}>
        <HealthRecords />
      </Accordion>
      <Accordion title={<AccordionTitle>Shared Media</AccordionTitle>} style={styles.accordion}>
        <SharedMedia editable />
      </Accordion>
      {data && patientUuid && (
        <EditDemographicModal
          isVisible={isVisible}
          onRequestClose={toggleIsVisible}
          showClose
          patient={data}
          clearCache={() => mutate()}
          isAdmin
          patientUuid={patientUuid}
        />
      )}
    </Sidebar>
  );
};

import { styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  header: {
    height: 62,
    backgroundColor: theme.lightSecondaryBackground,
    paddingHorizontal: 16,
    columnGap: 10,
  },

  left: {
    justifyContent: 'center',
  },

  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },

  right: {
    alignItems: 'flex-end',
    justifyContent: 'center',
  },

  title: {
    color: theme.accent,
  },

  centerText: {
    textAlign: 'center',
  },
}));

import React from 'react';
import { View } from 'react-native';

import { Text, useBrowserType, useTheme } from '@almond/ui';
import { cypressUtilities } from '@almond/utils';
import { useRouter } from 'expo-router';

import { SidebarButton } from '~/modules/ui';
import { useBookUrl, useCurrentUser } from '~/modules/user';

import { LogoutButton } from '../LogoutButton';

import { themedStyles } from './styles';

export type MenuProps = {
  name?: string;
  onClose: () => void;
};

export const Menu: React.FC<MenuProps> = props => {
  const [styles] = useTheme(themedStyles);
  const browserType = useBrowserType();
  const currentUser = useCurrentUser();
  const isInternal = currentUser?.permissions.includes('admin:all');
  const bookUrl = useBookUrl();
  const isPatient = !!currentUser?.patientUuid;
  const router = useRouter();

  return (
    <View style={styles.menu}>
      <Text size="m" fontStyle="bold" style={styles.menuText} testID="UserMenu-Username">
        {props.name}
      </Text>
      {isInternal && browserType === 'mobile' && (
        <SidebarButton
          href={bookUrl}
          hrefAttrs={cypressUtilities.isCypressRunning() ? undefined : { target: '_blank' }}
        >
          {'Book New Member'}
        </SidebarButton>
      )}
      {isPatient && (
        <SidebarButton
          onPress={() => {
            router.navigate('/(patient)/settings');
            props.onClose();
          }}
          testID="UserMenu-Settings"
        >
          {'Settings'}
        </SidebarButton>
      )}
      <LogoutButton onPress={props.onClose} />
    </View>
  );
};

import React from 'react';
import { View } from 'react-native';

import { Modal as NativeModal, Text, useBrowserTypeMap, useTheme } from '@almond/ui';

import { themedStyles } from './styles';

import type { ModalProps as NativeModalProps } from '@almond/ui';
import type { StyleProp, ViewStyle } from 'react-native';

export type ModalProps = Omit<NativeModalProps, 'onRequestClose'> & {
  onRequestClose: () => void;
  title: string;
  subtitle?: string;
  footer?: React.ReactNode;
  containerStyle?: StyleProp<ViewStyle>;
  contentContainerStyle?: StyleProp<ViewStyle>;
};

export const Modal: React.FC<ModalProps> = props => {
  const [styles] = useTheme(themedStyles);
  const { title, subtitle, children, footer, containerStyle, contentContainerStyle, ...restProps } = props;
  const { isDesktop, isMobile } = useBrowserTypeMap();

  return (
    <NativeModal {...restProps} containerStyle={[styles.container, isMobile && styles.containerMobile, containerStyle]}>
      <Text style={[styles.center, styles.title]} fontStyle="bold" size="xxl" accessibilityLevel={2}>
        {title}
      </Text>
      {subtitle && (
        <Text
          style={[styles.center, styles.subtitle, isDesktop && styles.subtitleDesktop]}
          size="m"
          accessibilityLevel={3}
        >
          {subtitle}
        </Text>
      )}
      <View style={[styles.contentContainer, isDesktop && styles.contentContainerDesktop, contentContainerStyle]}>
        {children}
      </View>
      {footer}
    </NativeModal>
  );
};

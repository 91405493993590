import { getShadow, sizes, styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(theme => ({
  container: {
    flex: 1,
    backgroundColor: theme.lightSecondaryBackground,
    paddingTop: sizes.SPACING_XXL,
  },

  containerDesktop: {
    alignItems: 'center',
  },

  title: {
    alignSelf: 'center',
  },

  content: {
    ...getShadow(),
    backgroundColor: theme.background,
    borderRadius: sizes.SPACING_S,
    padding: 36,
    marginHorizontal: sizes.SPACING_XXL,
    flex: 1,
    rowGap: sizes.SPACING_M,
  },

  contentDesktop: {
    width: 540,
  },

  contentContainer: {
    marginVertical: sizes.SPACING_XXL,
  },
}));

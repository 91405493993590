import React, { forwardRef, useMemo, useState } from 'react';

import { LOCAL_DATE_SHORT_FORMAT, REMOTE_DATE_FORMAT } from '@almond/date-and-time';
import { ActivityIndicator, Checkbox } from '@almond/ui';
import { combineUnstableStyles } from '@almond/utils';
import dayjs from 'dayjs';

import { BaseTodoItem, BaseTodoItemLite } from '../BaseTodoItem';
import { unstable_styles as cssUnstableStyles } from './LiveTodoItem.module.css';

import type { TodoCtaButton } from '../BaseTodoItem';
import type { TodoDetailOut } from '@almond/api-types';
import type { View } from 'react-native';

type LiveTodoItemProps = {
  item: TodoDetailOut;
  onComplete?: (isCompleted: boolean) => Promise<void>;
  ctaButtons?: TodoCtaButton[];
  displayLite?: boolean;
} & ({ isExpanded: boolean; onExpand: (isExpanded: boolean) => void } | { isExpanded?: never; onExpand?: never });

export const LiveTodoItem = forwardRef<View, LiveTodoItemProps>((props, ref) => {
  const { item, onComplete, isExpanded, onExpand, ctaButtons, displayLite } = props;
  const [isLoading, setIsLoading] = useState(false);

  const onChange = async (newVal: boolean) => {
    if (!onComplete) {
      return;
    }

    try {
      setIsLoading(true);
      await onComplete(newVal);
    } finally {
      setIsLoading(false);
    }
  };

  const dueDate = useMemo(
    () => item.dueDate && dayjs(item.dueDate, REMOTE_DATE_FORMAT).format(LOCAL_DATE_SHORT_FORMAT),
    [item.dueDate]
  );

  const TodoComponent = displayLite ? BaseTodoItemLite : BaseTodoItem;
  const checkboxSize = displayLite ? 16 : 20;

  return (
    <TodoComponent
      ref={ref}
      title={item.title}
      id={item.uuid}
      description={
        // eslint-disable-next-line @typescript-eslint/naming-convention
        <div dangerouslySetInnerHTML={{ __html: item.htmlDescription }} />
      }
      dueDate={dueDate && `Complete by ${dueDate}`}
      leftIcon={
        onComplete && (
          <>
            <Checkbox
              style={cssUnstableStyles.checkbox}
              checked={!!('completedAt' in item && item.completedAt)}
              onChange={onChange}
              isDisabled={isLoading || !props.onComplete}
              aria-label={'completedAt' in item && item.completedAt ? 'Mark as not complete' : 'Mark as complete'}
              size={checkboxSize}
            />
            {isLoading && (
              <ActivityIndicator
                style={combineUnstableStyles(cssUnstableStyles.checkbox, cssUnstableStyles.checkboxLoading)}
                size={checkboxSize}
                lineWidth={3}
              />
            )}
          </>
        )
      }
      isExpanded={isExpanded}
      onExpand={onExpand}
      ctaButtons={ctaButtons}
      dataSet={{ testclass: 'TodoItem', uuid: item.uuid }}
    />
  );
});

import './attachment.css';

import React from 'react';
import { View } from 'react-native';

import { useTheme } from '@almond/ui';

import { SmallPreview, useDocumentViewer } from '~/modules/documents';
import { ImageWithFallback } from '~/modules/ui';

import { themedStyles } from './styles';

import type { Attachment as AttachmentObject, DefaultGenerics } from 'stream-chat';

type AttachmentProps = {
  attachments: AttachmentObject<DefaultGenerics>[];
};

export const Attachment = (props: AttachmentProps) => {
  const [styles] = useTheme(themedStyles);
  const { attachments } = props;
  const { openDocument } = useDocumentViewer();

  const images = attachments.filter(attachment => attachment.type === 'image' && !attachment.og_scrape_url);
  const files = attachments.filter(attachment => attachment.type === 'file');

  const imageEle = !!images.length && (
    <View style={styles.imageContainer}>
      {images.map(
        (image, index, arr) =>
          image.image_url && (
            <View
              key={image.image_url}
              style={[
                styles.imageItem,
                // If only one image, make it full width
                // If there are an odd number of items >2,
                // make it full width BUT half height so it's
                // the same height as the other images
                arr.length % 2 === 1 && index === arr.length - 1 && styles.oddImageOut,
                arr.length === 1 && styles.soloImage,
              ]}
            >
              <ImageWithFallback
                style={styles.image}
                alt={image.fallback}
                onPress={() => {
                  openDocument(
                    image.image_url && image.fallback
                      ? { fileLocation: image.image_url, filename: image.fallback }
                      : null
                  );
                }}
                onPressStyles={styles.imagePressable}
                source={{ uri: image.image_url }}
              />
            </View>
          )
      )}
    </View>
  );

  const fileEle = files.map(file => (
    <View style={styles.fileContainer} key={file.asset_url}>
      {file.title && file.asset_url && (
        <SmallPreview
          style={styles.fileItem}
          fileName={file.title}
          fileLocation={file.asset_url}
          fileSize={file.file_size}
          onPreview={() => {
            openDocument(file.asset_url && file.title ? { fileLocation: file.asset_url, filename: file.title } : null);
          }}
        />
      )}
    </View>
  ));

  if (!images.length && !files.length) {
    return null;
  }

  return (
    <View
      style={[
        styles.attachmentContainer,
        // eslint-disable-next-line react-native/no-inline-styles
        {
          /* @ts-ignore */
          $$css: true,
          _: 'attachment-container',
        },
      ]}
    >
      {imageEle}
      {fileEle}
    </View>
  );
};

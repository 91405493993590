import React from 'react';
import { View } from 'react-native';

import { Link, PARKING_HREF, Text, useTheme } from '@almond/ui';

import { themedStyles } from './styles';

import type { AppointmentInfoOut } from '@almond/api-types';
import type { StyleProp, ViewStyle } from 'react-native';

export type PatientVisitItemProps = {
  item: AppointmentInfoOut;
  style?: StyleProp<ViewStyle>;
  lastItem?: boolean;
};

export const PatientVisitItem: React.FC<PatientVisitItemProps> = props => {
  const { item, style, lastItem } = props;
  const [styles] = useTheme(themedStyles);

  return (
    <View style={[styles.item, style, lastItem && styles.lastItem]}>
      <Text style={styles.itemTitle} fontStyle="medium">
        {item.appointmentTime}
      </Text>
      <Text>
        {`${item.isTelehealth ? 'Video' : 'Office'} Visit with ${
          item.providerName
        } | Visit Reason: ${item.visitReasons.join(', ')}`}
        {' | '}
        {item.rebookingUrl && (
          <Link url={item.rebookingUrl} size="s">
            {'Reschedule or Cancel'}
          </Link>
        )}
        {!item.isTelehealth && item.rebookingUrl && ' | '}
        {!item.isTelehealth && (
          <Link url={PARKING_HREF} size="s">
            {'Parking info & directions'}
          </Link>
        )}
      </Text>
    </View>
  );
};

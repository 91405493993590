import React from 'react';
import { View } from 'react-native';

import { IconButton, useBrowserType, useTheme } from '@almond/ui';
import { cypressUtilities } from '@almond/utils';

import { UserMenu } from '~/modules/auth';
import { SidebarButton } from '~/modules/ui';
import { useBookUrl } from '~/modules/user';

import { useAdminSidebar } from '../../hooks';

import { themedStyles } from './styles';

export const Right: React.FC = () => {
  const [styles] = useTheme(themedStyles);
  const browserType = useBrowserType();
  const { canOpenSidebar, toggleSidebar } = useAdminSidebar();
  const bookUrl = useBookUrl();

  return (
    <View style={styles.rightContainer}>
      {browserType === 'desktop' && (
        <SidebarButton
          style={styles.button}
          href={bookUrl}
          // Cypress does not support opening a new tab.
          hrefAttrs={cypressUtilities.isCypressRunning() ? undefined : { target: '_blank' }}
        >
          {'Book New Member'}
        </SidebarButton>
      )}
      {canOpenSidebar && <IconButton source="info" color="primary" onPress={toggleSidebar} />}
      {!canOpenSidebar && <UserMenu />}
    </View>
  );
};

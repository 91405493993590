/// ///////////////////////////////////////////////////////////
// This component was created by
// 1. Copying the default MessageOptions source code from stream-chat-react
//    https://github.com/GetStream/stream-chat-react/blob/master/src/components/Message/MessageOptions.tsx
// 2. Updating imports
// 3. Riping out threaded conversations
// 4. Riping out replies,
// 5. Riping out reactions
// 6. Passing handleDelete as a prop
//
// We will likely modify this component more in the future. These are the initial
// edits, but check the file's git history for more recent edits.
/// ///////////////////////////////////////////////////////////

import React from 'react';

import clsx from 'clsx';
import { MessageActions, useDialogIsOpen, useMessageContext } from 'stream-chat-react';

import { ActionsIcon as DefaultActionsIcon } from './icons';

import type { MessageContextValue, ReactEventHandler } from 'stream-chat-react';
import type { IconProps } from 'stream-chat-react/dist/types/types';

export type MessageOptionsProps = Partial<Pick<MessageContextValue, 'handleOpenThread'>> & {
  /* Custom component rendering the icon used in message actions button.
  This button invokes the message actions menu. */
  ActionsIcon?: React.ComponentType<IconProps>;
  /* Theme string to be added to CSS class names. */
  theme?: string;
  /* Pass custom handleDelete method to <MessageActions> */
  handleDelete?: ReactEventHandler;
};

const UnMemoizedMessageOptions = (props: MessageOptionsProps) => {
  const { ActionsIcon = DefaultActionsIcon, theme = 'simple', handleDelete } = props;

  const { initialMessage, message } = useMessageContext('MessageOptions');

  const messageActionsDialogIsOpen = useDialogIsOpen(`message-actions--${message.id}`);

  if (
    !message.type ||
    message.type === 'error' ||
    message.type === 'system' ||
    message.type === 'ephemeral' ||
    message.status === 'failed' ||
    message.status === 'sending' ||
    initialMessage
  ) {
    return null;
  }

  return (
    <div
      className={clsx(`str-chat__message-${theme}__actions str-chat__message-options`, {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'str-chat__message-options--active': messageActionsDialogIsOpen,
      })}
      data-testid="message-options"
    >
      <MessageActions ActionsIcon={ActionsIcon} handleDelete={handleDelete} />
    </div>
  );
};

export const MessageOptions = React.memo(UnMemoizedMessageOptions) as typeof UnMemoizedMessageOptions;

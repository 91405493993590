import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  modal: {
    height: 640,
    width: 640,
  },
  mobileModal: {
    height: '100%',
    width: '100%',
    padding: 0,
  },
  modalContent: {
    rowGap: sizes.SPACING_XL,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mobileHeader: {
    marginHorizontal: sizes.SPACING_M,
  },
  logo: {
    color: theme.accent,
  },
  image: {
    width: '100%',
    flex: 1,
  },
  iframe: {
    width: '100%',
    height: '100%',
    borderWidth: 0,
  },
  iosNotice: {
    textAlign: 'center',
  },
}));

import { getShadow, sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  right: {
    flexDirection: 'row',
    gap: sizes.SPACING_L,
    justifyContent: 'flex-end',
  },

  tabs: {
    flexDirection: 'row',
    gap: sizes.SPACING_M,
    alignItems: 'center',
    justifyContent: 'center',
  },

  tab: {
    height: sizes.SPACING_L,
    borderRadius: sizes.SPACING_M,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: sizes.SPACING_M,
    flexDirection: 'row',
    gap: sizes.SPACING_XS,
  },

  selectedTab: {
    backgroundColor: theme.accent,
    opacity: 0.7,
  },

  selectTabText: {
    color: theme.lightText,
  },
  indicator: {
    width: sizes.SPACING_S,
    height: sizes.SPACING_S,
    borderRadius: sizes.SPACING_S,
    backgroundColor: theme.accent,
  },

  disabled: {
    opacity: 0.5,
  },

  mobileMenu: {
    height: '100%',
    backgroundColor: theme.lightAccent,
    maxWidth: 450,
    ...getShadow(),
  },
  mobileMenuBackdrop: {
    height: '100%',
    width: '100%',
    position: 'absolute',
  },
  mobileMenuHeader: {
    backgroundColor: 'transparent',
  },
  mobileMenuItems: {
    paddingVertical: sizes.SPACING_L,
    paddingHorizontal: sizes.SPACING_XXL,
    justifyContent: 'space-between',
    gap: sizes.SPACING_L,
    minHeight: '100%',
  },
  mobileMenuItem: {
    paddingVertical: sizes.SPACING_M,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: sizes.SPACING_XS,
  },
  mobileMenuItemActive: {
    color: theme.accent,
  },
  mobileMenuReferContainer: {
    borderRadius: sizes.SPACING_M,
    padding: sizes.SPACING_L,
    backgroundColor: theme.lightSecondaryBackground,
    gap: sizes.SPACING_L,
    maxWidth: 300,
    alignSelf: 'center',
    marginBottom: sizes.SPACING_L,
  },
  mobileMenuReferText: {
    textAlign: 'center',
    color: theme.accent,
  },
  mobileMenuReferButton: {
    alignSelf: 'center',
    paddingHorizontal: sizes.SPACING_L,
  },
  mobileMenuSignOut: {
    alignSelf: 'center',
    textAlign: 'center',
  },
  mobileMenuSignOutText: {
    color: theme.accent,
    textDecorationLine: 'underline',
  },
}));

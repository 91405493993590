import React from 'react';
import { View } from 'react-native';

import { Text, useBrowserTypeMap, useTheme } from '@almond/ui';

import { ReferButton } from '../ReferButton';

import { themedStyles } from './styles';

export const ReferralCompact = () => {
  const [styles] = useTheme(themedStyles);
  const { isMobile } = useBrowserTypeMap();

  return (
    <View style={styles.container} testID="Referral">
      <Text style={[styles.title, isMobile && styles.titleMobile]} fontStyle="bold" size="m" testID="Title">
        {'Get $100 off next year’s membership'}
      </Text>
      <ReferButton />
    </View>
  );
};

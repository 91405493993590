import React from 'react';
import { View } from 'react-native';

import { PulseProvider, sizes, SkeletonLoader, useTheme } from '@almond/ui';

import { LoadingTodoItem } from '../BaseTodoItem';

import { themedStyles } from './styles';

export const LoadingTodoList = () => {
  const [styles] = useTheme(themedStyles);

  return (
    <PulseProvider duration={1000}>
      <View style={[styles.content, styles.category]}>
        <View style={[styles.categoryTitle, styles.categoryTitleWithItems]}>
          <SkeletonLoader.RoundedRectangle roundness={sizes.SPACING_S} height={20} width={100} />
        </View>
        <View style={styles.items}>
          <LoadingTodoItem />
          <LoadingTodoItem />
        </View>
      </View>
      <View style={[styles.content, styles.category]}>
        <View style={[styles.categoryTitle, styles.categoryTitleWithItems]}>
          <SkeletonLoader.RoundedRectangle roundness={sizes.SPACING_S} height={20} width={100} />
        </View>
        <View style={styles.items}>
          <LoadingTodoItem />
        </View>
      </View>
    </PulseProvider>
  );
};

import { styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(theme => ({
  loading: {
    flexGrow: 0,
  },
  subtitle: {
    marginTop: 8,
    marginBottom: 30,
  },
  content: {
    width: '100%',
  },
  image: {
    width: '100%',
    height: 300,
  },
  noItemsText: {
    marginTop: 24,
    backgroundColor: theme.darkSecondaryBackground,
    height: 38,
    lineHeight: 38,
    borderRadius: 38 / 2,
    alignSelf: 'center',
    paddingHorizontal: 30,
  },
  messageCtaWrapper: {
    marginTop: 20,
    alignItems: 'center',
    gap: 10,
  },
  messageCta: {
    height: 40,
    width: 'min-content' as any,
    paddingHorizontal: 30,
  },
}));

import React from 'react';

import { SENTRY_UNMASK_TEXT, Text, useTheme } from '@almond/ui';

import { themedStyles } from './styles';

import type { PropsWithChildren } from 'react';
import type { StyleProp, TextStyle } from 'react-native';

type AccordionTitleProps = PropsWithChildren<{
  style?: StyleProp<TextStyle>;
}>;

export const AccordionTitle: React.FC<AccordionTitleProps> = props => {
  const [styles] = useTheme(themedStyles);

  return (
    <Text fontStyle="bold" accessibilityLevel={4} style={[styles.text, SENTRY_UNMASK_TEXT, props.style]}>
      {props.children}
    </Text>
  );
};

import React from 'react';
import { View } from 'react-native';

import { Text, useBrowserTypeMap, useTheme } from '@almond/ui';

import { themedStyles } from './styles';

type ItemProps = {
  index: number;
  text: string;
};

const Item = (props: ItemProps) => {
  const [styles] = useTheme(themedStyles);

  return (
    <View style={styles.howItWorksSection}>
      <Text style={[styles.textAccent, styles.howItWorksIndex]} fontStyle="bold" size="l">
        {props.index + 1}
      </Text>
      <Text fontStyle="bold" size="s">
        {props.text}
      </Text>
    </View>
  );
};

export const HowItWorks = () => {
  const [styles] = useTheme(themedStyles);
  const { isMobile } = useBrowserTypeMap();

  return (
    <View style={[styles.howItWorks, isMobile && styles.howItWorksMobile]}>
      <Text style={[styles.textAccent, styles.textCenter]} fontStyle="bold" size="xl">
        {'How it works'}
      </Text>
      {[
        'Share your link with a friend',
        'They get $100 off their first year of membership',
        'You get $100 off your next year’s membership',
      ].map((item, index) => (
        <Item key={index} index={index} text={item} />
      ))}
    </View>
  );
};

import React from 'react';
import { Image, View } from 'react-native';

import {
  ActivityIndicator,
  CONTACT_US_PHONE_NUMBER,
  Link,
  ScrollView,
  Text,
  useBrowserType,
  useTheme,
} from '@almond/ui';

import { ModalListPreview, useDocumentViewer } from '~/modules/documents';
import { ErrorContainer } from '~/modules/logging';
import { useDocuments } from '~/modules/messaging';
import { PatientPageTitle } from '~/modules/routing';
import { useCurrentUser } from '~/modules/user';
import { useImages } from '~assets';

import themedStyles from './styles';

export { ErrorContainer as ErrorBoundary };

const HealthRecords: React.FC = () => {
  const browserType = useBrowserType();
  const [styles] = useTheme(themedStyles);
  const currentUser = useCurrentUser();
  const images = useImages();
  const { openDocument } = useDocumentViewer();
  const contentContainerStyle = [styles.content, browserType === 'mobile' && styles.mobileContent];
  const patientUuid = currentUser?.patientUuid as string | null;

  const { data, isLoadingData, loadError } = useDocuments(patientUuid, 'health_record', { revalidateOnMount: true });

  if (isLoadingData) {
    return (
      <View style={[styles.container, ...contentContainerStyle]}>
        <PatientPageTitle title="Health Records" />

        {/** TODO add loading shimmer here */}
        <ActivityIndicator style={styles.loading} />
      </View>
    );
  }

  if (loadError || !data?.length) {
    const image = loadError ? images.universe3 : images.plant1;

    let errorText = 'There was an error loading your Health Records.';

    if (typeof loadError === 'string') {
      errorText = loadError;
    } else if (typeof loadError === 'object' && 'message' in loadError && typeof loadError.message === 'string') {
      errorText = loadError.message;
    }

    const message = loadError ? (
      <>
        <Text size="l" style={styles.message}>
          {errorText}
        </Text>
        <Text size="l" style={styles.message}>
          {`Try refreshing the page. If that doesn't fix the issue, give us a call at `}
          <Link url={`+1${CONTACT_US_PHONE_NUMBER.replace(/[ ()-]/g, '')}`} urlType="phoneNumber" size="l">
            {CONTACT_US_PHONE_NUMBER}
          </Link>
        </Text>
      </>
    ) : (
      <>
        <Text size="xxl" style={styles.message} fontStyle="book">
          Nothing to see here yet :)
        </Text>
        <Text size="l" style={styles.message}>
          This is where your care team will upload files like care plans, labwork and imaging results.
        </Text>
      </>
    );

    return (
      <ScrollView style={styles.container} contentContainerStyle={contentContainerStyle}>
        <PatientPageTitle title="Health Records" />
        <View style={styles.constrainWidth}>
          <Image source={image} style={styles.image} resizeMode="contain" />
          {message}
        </View>
      </ScrollView>
    );
  }

  return (
    <ScrollView style={styles.container} contentContainerStyle={contentContainerStyle}>
      <PatientPageTitle title="Health Records" />
      <View role="list" style={styles.constrainWidth}>
        {data?.map(d => (
          <View key={d.uuid} role="listitem" style={styles.item}>
            <ModalListPreview
              item={d}
              displayUploadDate
              onOpenPreview={item => openDocument(item)}
              patientUuid={patientUuid}
              testID="HealthRecord"
            />
          </View>
        ))}
      </View>
    </ScrollView>
  );
};

export default HealthRecords;

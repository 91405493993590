import { CIRCULAR_STD_MEDIUM, sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  item: {
    paddingVertical: sizes.SPACING_S,
    rowGap: sizes.SPACING_XS,
  },

  itemTitle: {
    color: theme.darkText,
  },

  link: {
    fontSize: sizes.FONT_SIZE_S,
    fontFamily: CIRCULAR_STD_MEDIUM,
    textDecorationLine: 'underline',
    color: theme.primary,
  },
}));

import React from 'react';

import { Text } from '@almond/ui';
import { useToggle } from '@almond/utils';

import { SectionWithItems } from '../SectionWithItems';
import { ViewMoreButton } from '../ViewMoreButton';
import { ProgramItem } from './ProgramItem';

const programs = [
  {
    id: 'pcos',
    title: 'The PCOS Program',
    description:
      // eslint-disable-next-line max-len
      'Address root causes of PCOS, design a treatment plan, and work with your ObGyn and health coach to adapt as needed.',
    learnMoreHref: 'https://www.almondobgyn.com/the-pcos-membership',
    bookTitle: 'Book Intro Call',
    bookUrl: 'https://almondhealth.as.me/PCOS-Membership-Registration-Call',
  },
  {
    id: 'health-coaching',
    title: 'Health Coaching',
    description:
      // eslint-disable-next-line max-len
      'Create a customized plan to reach your health goals using a root cause approach and expert health coach guidance.',
    learnMoreHref: 'https://www.almondobgyn.com/health-coaching',
    bookTitle: 'Book Intro Call',
    bookUrl: 'https://almondhealth.as.me/healthcoaching',
  },
  {
    id: 'menopause',
    title: 'The Menopause Program',
    description:
      'Manage perimenopause and menopause with a root cause approach with support from your ObGyn and health coach.',
    learnMoreHref: 'https://www.almondobgyn.com/the-menopause-program',
    bookTitle: 'Book Intro Call',
    bookUrl: 'https://almondhealth.as.me/menopause-intro',
  },
  {
    id: 'baseline',
    title: 'Baseline',
    description: 'Get 35+ lab tests, designed especially for women, with personalized insights from an ObGyn.',
    learnMoreHref: 'https://www.almondobgyn.com/baseline',
    bookTitle: 'Get Started',
    bookHref: '/messages?input_hint=baseline_signup',
  },
] as const;

export const Programs = () => {
  const [expanded, toggleExpanded] = useToggle(false);

  return (
    <SectionWithItems
      mode="accent"
      title="Programs"
      footer={<ViewMoreButton isExpanded={expanded} onPress={toggleExpanded} />}
      isCollapsed={!expanded}
    >
      <Text>
        {'Learn about Almond programs you can add-on to your membership to support you in feeling your best.'}
      </Text>
      {programs.map(program => (
        <ProgramItem item={program} key={program.id} />
      ))}
    </SectionWithItems>
  );
};

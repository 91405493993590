/// ///////////////////////////////////////////////////////////
// This component was created by
// 1. Copying the default TypingIndicator source code from stream-chat-react
//    https://github.com/GetStream/stream-chat-react/blob/master/src/components/TypingIndicator/TypingIndicator.tsx
// 2. Updating imports
// 3. Using the components from our UI package instead of the web ones
// 4. Refactoring typingInChannel and typingInThread to match our code standards
//
// We will likely modify this component more in the future. These are the initial
// edits, but check the file's git history for more recent edits.
/// ///////////////////////////////////////////////////////////

import React from 'react';
import { View } from 'react-native';

import { Text, useTheme } from '@almond/ui';
import { useChannelStateContext, useChatContext, useTypingContext } from 'stream-chat-react';

import { getChannelPatient } from '~/modules/user';

import { useInputBanner } from '../../hooks';
import { joinTypingUsers } from '../../utils';

import { themedStyles } from './styles';

export type TypingIndicatorProps = {
  /** Whether the typing indicator is in a thread */
  threadList?: boolean;
};

/**
 * TypingIndicator lists users currently typing, it needs to be a child of Channel component
 */
const UnMemoizedTypingIndicator = (props: TypingIndicatorProps) => {
  const { threadList } = props;

  const { channelConfig, thread } = useChannelStateContext('TypingIndicator');
  const { client, channel } = useChatContext('TypingIndicator');
  const { typing = {} } = useTypingContext('TypingIndicator');
  const patientUuid = getChannelPatient(channel)?.patient_uuid as string | null;
  const { inputBanner } = useInputBanner(patientUuid);

  const typingInChannel = (() => {
    if (threadList) return [];

    return Object.values(typing).filter(typingItem => typingItem.user?.id !== client.user?.id && !typingItem.parent_id);
  })();

  const typingInThread = (() => {
    if (!threadList) return [];

    return Object.values(typing).filter(
      typingItem => typingItem.user?.id !== client.user?.id && typingItem.parent_id === thread?.id
    );
  })();

  const typingUserList = (threadList ? typingInThread : typingInChannel)
    .map(({ user }) => user?.name || user?.id)
    .filter(Boolean) as string[];

  const joinedTypingUsers = joinTypingUsers(typingUserList);

  const isTypingActive = (threadList && typingInThread.length) || (!threadList && typingInChannel.length);

  const [styles] = useTheme(themedStyles);

  if (!channelConfig?.typing_events || !isTypingActive) {
    if (!inputBanner) return null;

    return (
      <View style={styles.bottomMessageContainer}>
        <Text style={styles.slaMessage} testID="SlaMessage">
          {inputBanner}
        </Text>
      </View>
    );
  }

  return (
    <View style={styles.bottomMessageContainer}>
      <Text style={styles.typingIndicator} size="m" testID="TypingIndicator">
        {joinedTypingUsers}
      </Text>
    </View>
  );
};

export const TypingIndicator = React.memo(UnMemoizedTypingIndicator) as typeof UnMemoizedTypingIndicator;

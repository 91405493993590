import type { PatientDetailOut } from '@almond/api-types';

const membershipSteps = {
  title: 'Next Steps',
  steps: [
    {
      title: 'Complete your Health History',
      description: 'Tell us about what’s going on and your health goals.',
    },
    {
      title: 'Your Provider Will Review',
      description: `Before your visit your provider will review your health history \
so they are prepared to guide you in meeting your health goals.`,
    },
    {
      title: 'Come to Your Visit',
      description: `Please arrive 15 minutes early to ensure we can make use of your full visit time. \
Please cancel or reschedule at least 24 hours in advance to avoid a charge.`,
    },
  ],
};

const STEPS_BY_PROGRAM = {
  baseline: {
    title: 'Baseline Next Steps',
    steps: [
      {
        title: 'Share Your History',
        description:
          "Tell us about what’s going on and your health goals, we'll send over your lab order within 1 business day.",
      },
      {
        title: 'Get Labwork',
        description: 'Visit the lab on your order form.',
      },
      {
        title: 'Review Results',
        description: `We'll send an analysis from our Board Certified physician in 1 - 2 weeks. Once you receive \
your results you can book a video visit to go over any questions.`,
      },
    ],
  },
  gynecology_membership: membershipSteps,
  virtual_membership: membershipSteps,
} as const;

export const useNextSteps = (patient: PatientDetailOut | undefined) => {
  if (!patient)
    return {
      isHistoryComplete: false,
      stepsByProgram: STEPS_BY_PROGRAM.gynecology_membership,
      hasBaseline: false,
      product: 'gynecology_membership',
    };

  const hasBaseline = patient.products?.some(product => product.almondProductKey === 'baseline');
  const isBaselineIntakeComplete = patient.intakeForms?.some(form => form.formType === 'baseline_intake');
  const isBaselineComplete = hasBaseline ? isBaselineIntakeComplete : true;
  const membershipType = patient?.profile?.subscription?.membershipType;
  const product = hasBaseline ? 'baseline' : membershipType;
  const stepsByProgram = (() => {
    if (hasBaseline) return STEPS_BY_PROGRAM.baseline;
    if (membershipType === 'virtual_membership') return STEPS_BY_PROGRAM.virtual_membership;

    return STEPS_BY_PROGRAM.gynecology_membership;
  })();

  const isHistoryComplete =
    patient?.historyData &&
    patient?.consent &&
    patient?.pharmacy &&
    patient?.profile?.address &&
    patient?.referral &&
    isBaselineComplete;

  return { isHistoryComplete, stepsByProgram, hasBaseline, product };
};

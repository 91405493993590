import React, { useMemo } from 'react';
import { View } from 'react-native';

import {
  ConnectedCharacterCount,
  ConnectedDateInput,
  ConnectedSimpleSelectInput,
  ConnectedTextInput,
  Form,
  SubmitButton,
  Text,
  useBrowserTypeMap,
  useTheme,
} from '@almond/ui';
import { useEvent } from '@almond/utils';
import { zodResolver } from '@hookform/resolvers/zod';

import { Modal } from '~/modules/ui';

import { CATEGORIES } from '../../config';
import { TITLE_MAX_LENGTH, validationSchema } from '../../validations';

import { themedStyles } from './styles';

import type { TodoFormActionProps } from '../../types';
import type { ModalProps } from '~/modules/ui';
import type { z } from 'zod';

const CATEGORY_OPTIONS = CATEGORIES.map(({ id, title }) => ({ value: id, label: title }));

type TodoFormModalProps<TodoItem extends { uuid: string; category?: string; dueDate?: string | null }> = Pick<
  ModalProps,
  'isVisible'
> &
  TodoFormActionProps<TodoItem> & {
    model: Partial<TodoItem> | null;
    onRequestClose: (newTodo?: TodoItem) => void;
  };

export const TodoFormModal = <TodoItem extends { uuid: string; category?: string; dueDate?: string | null }>(
  props: TodoFormModalProps<TodoItem>
) => {
  const { isVisible, onRequestClose, model, ...todoFormAction } = props;
  const [styles] = useTheme(themedStyles);
  const { isDesktop } = useBrowserTypeMap();

  const isEdit = !!model?.uuid;

  const defaultValues = useMemo(() => {
    if (!model) {
      return;
    }

    // Make sure title and description are strings (not undefined) when passed to form
    return {
      title: '',
      description: '',
      ...model,
    };
  }, [model]);

  const onSubmit = useEvent(async (values: z.infer<typeof validationSchema>) => {
    try {
      const data = await todoFormAction.onSubmit(values);

      onRequestClose(data);
    } catch (e) {
      // Errors will be surfaced by the error value returned from useALmondApiMutation.
      // Catching here so the uncaught error doesn't propagate up to the top error handler
    }
  });

  return (
    <Modal
      isVisible={isVisible}
      title={`${isEdit ? 'Edit' : 'New'} To Do Item`}
      onRequestClose={() => onRequestClose()}
      showClose
      containerStyle={styles.modalContainer}
      contentContainerStyle={isDesktop && styles.modalContent}
    >
      <Form
        name={`${isEdit ? 'Edit' : 'New'} To Do Item`}
        defaultValues={defaultValues}
        resolver={zodResolver(validationSchema)}
        onSubmit={onSubmit}
        isDisabled={todoFormAction.isLoading}
        isLoading={todoFormAction.isLoading}
      >
        <View testID="TodoFormModal">
          <ConnectedSimpleSelectInput name="category" options={CATEGORY_OPTIONS} testID="Category" />
          <ConnectedTextInput
            name="title"
            label="Name"
            placeholder="What is the name of the to do?"
            accessibilityLabel="Title"
          />
          <ConnectedDateInput name="dueDate" label="Due Date" storeInRemoteFormat />
          <ConnectedCharacterCount name="title">
            {count => {
              return (
                <Text size="m" style={[styles.count, count > TITLE_MAX_LENGTH && styles.countInvalid]}>
                  {count}/{TITLE_MAX_LENGTH} characters
                </Text>
              );
            }}
          </ConnectedCharacterCount>
          <ConnectedTextInput
            name="description"
            placeholder="Any other info to add? Resources, links, ideas for how to accomplish?"
            multiline
            style={styles.description}
            accessibilityLabel="Description"
          />
          <View style={styles.footer}>
            {todoFormAction.error && (
              <Text style={styles.error} size="m">
                There was an error saving the To Do item. Please try again.
              </Text>
            )}
            <SubmitButton type="accent" style={styles.submit}>
              {isEdit ? 'Save Changes' : 'Create new To Do item'}
            </SubmitButton>
          </View>
        </View>
      </Form>
    </Modal>
  );
};

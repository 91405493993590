import React from 'react';
import { useWindowDimensions, View } from 'react-native';

import { Header } from '~/modules/ui';

import { PATIENT_HEADER_THRESHOLD_WIDTH } from '../../config';
import { MobileMenu } from './MobileMenu';
import { Right } from './Right';

export const PatientHeader: React.FC = () => {
  const windowDimensions = useWindowDimensions();
  const showMobileTabs = windowDimensions.width < PATIENT_HEADER_THRESHOLD_WIDTH;

  return (
    <View testID="PatientHeader">
      <Header left={showMobileTabs ? <MobileMenu /> : null} right={<Right />} leftAlignTitle={!showMobileTabs} />
    </View>
  );
};

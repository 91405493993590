import { useBrowserTypeMap } from '@almond/ui';
import { useGlobalSearchParams, useRouter } from 'expo-router';

export const useSidebar = (isOnSidebarPage: boolean = true) => {
  const router = useRouter();
  const searchParams = useGlobalSearchParams();
  const { isMobile } = useBrowserTypeMap();
  const isSidebarOpen = isMobile && searchParams.sidebar === 'true' && isOnSidebarPage;
  const canOpenSidebar = isMobile && !isSidebarOpen && isOnSidebarPage;
  const toggleSidebar = () => {
    router.setParams({ sidebar: searchParams.sidebar === 'true' ? undefined : 'true' });
  };

  return { isSidebarOpen, toggleSidebar, canOpenSidebar };
};

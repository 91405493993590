import React from 'react';
import { Text, View } from 'react-native';

import { Link, Stack } from 'expo-router';

import styles from './styles';

const Root404: React.FC = () => {
  return (
    <>
      <Stack.Screen options={{ title: 'Oops!' }} />
      <View style={styles.container}>
        <Text style={styles.title}>{"This screen doesn't exist."}</Text>

        <Link href="/" style={styles.link}>
          <Text style={styles.linkText}>{'Go to home screen!'}</Text>
        </Link>
      </View>
    </>
  );
};

export default Root404;

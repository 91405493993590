import { useCallback, useEffect } from 'react';

import { useAlmondApiMutation, useAlmondApiQuery } from '~/modules/api';

export const useMarkTodosAsRead = (patientUuid: string | undefined) => {
  const { data, mutate } = useAlmondApiQuery(
    patientUuid ? `/patients/${patientUuid as '{patient_uuid}'}/todos/` : null,
    {
      query: { archived_or_completed: false },
    }
  );

  const { trigger } = useAlmondApiMutation(
    'post',
    patientUuid ? `/patients/${patientUuid as '{patient_uuid}'}/todos/read/` : null
  );
  const markAllAsRead = useCallback(async () => {
    if (!patientUuid || !data?.todos.length) return;

    const todoUuids = data.todos.filter(todo => !todo.readAt).map(todo => todo.uuid);

    if (!todoUuids.length) return;

    const result = await trigger({
      todoUuids,
    });

    const now = new Date().toISOString();

    mutate(
      oldData => ({
        count: oldData?.count || 0,
        todos: (oldData?.todos || []).map(todo => {
          if (result.todoUuids.includes(todo.uuid)) {
            return {
              ...todo,
              readAt: now,
            };
          }

          return todo;
        }),
      }),
      { revalidate: false }
    );
  }, [patientUuid, data?.todos, trigger, mutate]);

  useEffect(() => {
    markAllAsRead();
  }, [data?.todos, markAllAsRead]);
};

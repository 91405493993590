import React, { type FC } from 'react';
import { Pressable } from 'react-native';

import { useTheme } from '@almond/ui';

import { FileThumbnail } from '../FileThumbnail';

import { themedStyles } from './styles';

import type { ExtendedDocumentOut } from '../../types';
import type { PressableProps, StyleProp, ViewStyle } from 'react-native';

type MediumPreviewProps = {
  document: ExtendedDocumentOut;
  onPress: PressableProps['onPress'];
  style?: StyleProp<ViewStyle>;
};

export const MediumPreview: FC<MediumPreviewProps> = ({ document, onPress, style }) => {
  const [styles] = useTheme(themedStyles);

  return (
    <Pressable onPress={onPress} role="button" style={[style, styles.container]}>
      <FileThumbnail fileLocation={document.fileLocation} filename={document.filename} />
    </Pressable>
  );
};

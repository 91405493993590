import React from 'react';

import { Text } from '@almond/ui';
import { Link, useGlobalSearchParams, useSegments } from 'expo-router';
import { z } from 'zod';

import { userIdToConversationId } from '~/modules/user';

import type { TextProps } from '@almond/ui';

export const almondTodoEventSchema = z.object({
  event_type: z.enum(['created', 'completed', 'archived']),
  todo_uuids: z.string().array().readonly(),
});

type AlmondTodoEvent = z.infer<typeof almondTodoEventSchema>;

const textProps = {
  size: 's',
  family: 'SystemFont',
} as const;

export const TodoMessage = (props: {
  event: AlmondTodoEvent;
  eventCausedByPatient: boolean;
  currentUserIsPatient: boolean;
  patientName: string;
  style?: TextProps['style'];
  linkStyle?: TextProps['style'];
  channelId?: string;
}): React.ReactElement => {
  const { event, eventCausedByPatient, currentUserIsPatient, patientName, style, linkStyle, channelId } = props;

  const searchParams = useGlobalSearchParams();
  const segments = useSegments();

  let eventInitiator;

  if (currentUserIsPatient) {
    eventInitiator = eventCausedByPatient ? 'You' : 'A member of your Care Team';
  } else {
    eventInitiator = eventCausedByPatient ? `${patientName}` : `A member of ${patientName}'s Care Team`;
  }

  const pronoun = currentUserIsPatient ? 'your' : 'their';

  const selected = event.todo_uuids.length === 1 ? event.todo_uuids[0] : '';

  // TODO update to relative link with `relativeToDirectory: true`, with Expo v52
  const href = (() => {
    if (currentUserIsPatient) {
      return {
        pathname: `/(patient)/todos`,
        params: { selected },
      } as const;
    }

    if ((segments as string[]).includes('[elationId]') && 'elationId' in searchParams) {
      return {
        pathname: `/(admin)/elation/[elationId]/todos`,
        params: { selected, elationId: searchParams.elationId },
      } as const;
    }

    const conversationId = userIdToConversationId(channelId ?? '');

    return {
      pathname: `/(admin)/(conversations)/[conversationId]/todos`,
      params: { selected, conversationId },
    } as any; // type checking not working
  })();

  const todoList =
    currentUserIsPatient || channelId ? (
      <Link href={href}>
        <Text {...textProps} style={[style, linkStyle]}>
          To Do List
        </Text>
      </Link>
    ) : (
      'To Do List'
    );

  if (event.event_type === 'created') {
    const noun = event.todo_uuids.length === 1 ? 'a new item' : `${event.todo_uuids.length} new items`;

    return (
      <Text {...textProps} style={style}>
        {`${eventInitiator} added ${noun} to ${pronoun} `}
        {todoList}
        {'.'}
      </Text>
    );
  }

  if (event.event_type === 'archived') {
    return (
      <Text {...textProps} style={style}>
        {`${eventInitiator} archived an item on ${pronoun} `}
        {todoList}
        {'.'}
      </Text>
    );
  }

  if (event.event_type === 'completed') {
    const action = eventCausedByPatient ? 'completed an item' : 'marked an item as complete';

    return (
      <Text {...textProps} style={style}>
        {`${eventInitiator} ${action} on ${pronoun} `}
        {todoList}
        {'.'}
      </Text>
    );
  }

  throw new Error(`Unexpected event_type: ${event.event_type}`);
};

import { StyleSheet } from 'react-native';

import { sizes, styleSheetFactory, SYSTEM_FONT_FAMILY } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  container: {
    backgroundColor: theme.skeletonLoader,
    alignItems: 'center',
    justifyContent: 'center',
    padding: sizes.SPACING_S,
    flexDirection: 'row',
    columnGap: sizes.SPACING_M,
  },

  button: {
    height: sizes.SPACING_L,
    backgroundColor: theme.background,
    borderColor: theme.secondaryTextDark,
    borderWidth: StyleSheet.hairlineWidth,
    width: 'auto',
    // Prevent button from pushing next line down
    marginVertical: -sizes.SPACING_XS,
  },

  buttonLabel: {
    color: theme.secondaryTextDark,
    fontFamily: SYSTEM_FONT_FAMILY,
    fontSize: sizes.FONT_SIZE_XXS,
    marginHorizontal: 0,
    fontWeight: '700',
  },
}));

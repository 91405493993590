import React from 'react';

import { ActivityIndicator } from '@almond/ui';
import { Redirect, useLocalSearchParams } from 'expo-router';

import { useAlmondApiQuery } from '~/modules/api';

// Redirect from an Almond patient UUID to the corresponding Stream conversation
// This is a utility page to make it easier to dereference a patient UUID
// seen in a Sentry error.
const UuidPage = () => {
  const { patient_uuid: patientUuid } = useLocalSearchParams();

  const { data } = useAlmondApiQuery(patientUuid ? `/patients/${patientUuid as '{patient_uuid}'}/` : null);

  const auth0Id = data?.authId?.replace('auth0|', '');

  if (auth0Id) {
    return <Redirect href={`/(admin)/(conversations)/${auth0Id}`} />;
  }

  return <ActivityIndicator />;
};

export default UuidPage;

import React, { type FC } from 'react';
import { Pressable, View } from 'react-native';

import { IconButton, Text, useTheme } from '@almond/ui';

import { useDoesFileExist } from '../../hooks';
import { formatFileName, formatFileSize, getFileType } from '../../utils';
import { FileThumbnail } from '../FileThumbnail';

import { deleteSize, themedStyles } from './styles';

import type { PressableProps, ViewProps } from 'react-native';

export type SmallPreviewProps = Pick<ViewProps, 'role' | 'style'> & {
  fileName: string;
  fileLocation: string | null | undefined;
  fileSize?: number | string;
  onDelete?: PressableProps['onPress'];
  isLoading?: boolean;
  isError?: boolean;
  onRetry?: PressableProps['onPress'];
  onPreview?: PressableProps['onPress'];
};

export const SmallPreview: FC<SmallPreviewProps> = props => {
  const { onDelete, onPreview, isLoading, isError, onRetry, fileName, fileLocation, fileSize, style, ...viewProps } =
    props;
  const [styles] = useTheme(themedStyles);
  const fileType = getFileType(fileName);

  const { doesFileExist, isLoading: existsLoading } = useDoesFileExist(fileLocation);
  const assumeFileExists = existsLoading || doesFileExist;

  const Container = onPreview && assumeFileExists ? Pressable : View;
  const pressableProps = onPreview && assumeFileExists ? { role: 'button' as const, onPress: onPreview } : {};

  return (
    <Container
      style={[styles.container, !assumeFileExists && styles.deletedContainer, style]}
      {...viewProps}
      testID="SmallPreview"
      {...pressableProps}
    >
      <FileThumbnail
        filename={fileName}
        fileLocation={fileLocation || undefined}
        style={styles.thumbnail}
        isLoading={isLoading}
        isError={isError}
        onRetry={onRetry}
      />
      <View style={styles.textWrapper}>
        <Text style={styles.fileName} fontStyle="black" size="m" numberOfLines={1}>
          {assumeFileExists ? formatFileName(fileName) : 'File Deleted'}
        </Text>
        {assumeFileExists && Boolean(fileSize) && (
          <Text style={styles.fileSize} size="s">
            {fileSize == null ? '' : formatFileSize(fileSize)}
          </Text>
        )}
      </View>

      {onDelete && (
        <IconButton
          source="close"
          color="secondaryTextDark"
          size={deleteSize * 0.5}
          style={styles.deleteButton}
          onPress={onDelete}
          accessibilityLabel={`Remove ${fileType === 'image' ? 'image' : 'file'}`}
        />
      )}
    </Container>
  );
};

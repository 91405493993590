import React, { createContext, useContext, useMemo, useState } from 'react';

import { sendCarePlanSchema } from '@almond/extension-utils';
import { useEvent } from '@almond/utils';
import { useGlobalSearchParams, useRouter, useSegments } from 'expo-router';

import { useAlmondApiMutation } from '~/modules/api';

import { useExtensionMessageListener, useSynchronizeVisitNoteStatus } from '../../hooks';
import { parseTodoFromElation } from '../../utils';
import { usePreferredNamePromise } from './usePreferredNamePromise';

import type { Todos } from '../../hooks';
import type { CarePlan } from '@almond/extension-utils';
import type { ParsedVisitNote, PendingTodoItem } from '~/modules/todos/types';
import type { ElationDetailsParams } from '~types';

const VisitNoteIdContext = createContext<{
  parsedVisitNote: ParsedVisitNote;
  setPendingTodos: (fn: (prevVal: PendingTodoItem[]) => PendingTodoItem[]) => void;
  setPendingMessage: (message: string) => void;
  clearPendingState: () => void;
  visitNoteId: string | null;
  showBulkUi: boolean;
  isLoading: boolean;
  error: any;
} | null>(null);

type ChromeBrowserExtensionCarePlanHandlerProps<Category extends { id: string; title: string }> =
  React.PropsWithChildren<{
    // To prevent circular dependencies
    categories: readonly Category[];
    todos: Todos;
  }>;

export const ChromeBrowserExtensionCarePlanHandler = <Category extends { id: string; title: string }>(
  props: ChromeBrowserExtensionCarePlanHandlerProps<Category>
) => {
  const preferredName = usePreferredNamePromise();

  const segments = useSegments();
  const router = useRouter();
  const searchParams = useGlobalSearchParams<ElationDetailsParams>();
  const { trigger, error } = useAlmondApiMutation('get', `/visit_notes/{visit_note_id}/`);

  const [parsedVisitNote, setParsedVisitNote] = useState<{ todos: PendingTodoItem[]; message: string }>({
    todos: [],
    message: '',
  });
  const [visitNoteId, setVisitNoteId] = useState<string | null>(null);
  // Separate loadingflag (instead of using the one from the mutation hook) so that it
  // shows as loading during the setTimeout()
  const [isLoading, setIsLoading] = useState(false);

  useSynchronizeVisitNoteStatus(props.todos, visitNoteId);

  const clearPendingState = useEvent(() => {
    setVisitNoteId(null);
    setParsedVisitNote({ todos: [], message: '' });
  });

  const processCarePlan = useEvent(async (elationData: Pick<CarePlan, 'visitNoteId'>) => {
    if (segments.at(-1) !== 'todos') {
      router.push({
        pathname: '/(admin)/elation/[elationId]/todos',
        params: searchParams,
      });
    }

    setIsLoading(true);
    setVisitNoteId(elationData.visitNoteId);

    // If we request the visit note immediately, AND the button in Elation was pushed as a field
    // was being blurred, it's possible the auto-update won't have finished at this point. So, let's
    // wait a brief moment before fetching the visit note to give it a chance to persist on the
    // Elation server.
    await new Promise(r => {
      setTimeout(r, 500);
    });

    const data = await trigger({ visit_note_id: elationData.visitNoteId });

    const todos = data.bullets
      .filter(a => a.category === 'Instr')
      .map(item =>
        parseTodoFromElation(
          item.text,
          item.children.filter(child => child.category === 'Instr'),
          props.categories
        )
      )
      .filter(todo => {
        // Hide placeholder todos
        return todo.title !== 'Title' || todo.description !== 'Description';
      })
      .map(todo => ({
        // If a placeholder todo's description or title wasn't edited, clear the field
        ...todo,
        title: todo.title === 'Title' ? '' : todo.title,
        description: todo.description === 'Description' ? '' : todo.description,
      }));

    const followupText = data.bullets.find(a => a.category === 'Followup')?.text.trim();

    const message = followupText ? `Hi ${await preferredName}, ${followupText}` : '';

    setParsedVisitNote({ todos, message });

    setIsLoading(false);
  });

  const contextValue = useMemo(
    () => ({
      parsedVisitNote,
      setPendingTodos: (fn: (prevVal: PendingTodoItem[]) => PendingTodoItem[]) => {
        setParsedVisitNote(prevVal => ({ ...prevVal, todos: fn(prevVal.todos) }));
      },
      setPendingMessage: (message: string) => {
        setParsedVisitNote(prevVal => ({ ...prevVal, message }));
      },
      clearPendingState,
      showBulkUi: !!visitNoteId,
      visitNoteId,
      isLoading,
      error,
    }),
    [parsedVisitNote, setParsedVisitNote, clearPendingState, visitNoteId, isLoading, error]
  );

  useExtensionMessageListener(sendCarePlanSchema, processCarePlan);

  if (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined') {
    (window as any).artemisMockSendCarePlan = processCarePlan;
  }

  return <VisitNoteIdContext.Provider value={contextValue}>{props.children}</VisitNoteIdContext.Provider>;
};

export const useChromeBrowserExtensionCarePlanVisitNoteContext = () => {
  const context = useContext(VisitNoteIdContext);

  if (!context) {
    throw new Error(
      'useChromeBrowserExtensionCarePlanVisitNoteContext() can only be called within the appropriate context provider.'
    );
  }

  return context;
};

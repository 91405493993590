import { styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(theme => ({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.background,
    height: '100%',
  },

  image: {
    width: 432,
    height: 356,
  },

  title: {
    textAlign: 'center',
    color: theme.darkText,
  },
}));

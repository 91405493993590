import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

import { useFocusEffect } from 'expo-router';

import type { PropsWithChildren } from 'react';

type HeaderOptions = {
  onBack?: (() => void) | null;
  title?: string | null;
};

type HeaderOptionsContext = {
  setChildOptions: (options: HeaderOptions[]) => void;
  options: HeaderOptions[];
};

export const HeaderContext = createContext<HeaderOptionsContext>({
  setChildOptions: () => undefined,
  options: [],
});

type ArtemisHeaderOptionsProps = PropsWithChildren<HeaderOptions>;

// This component allows child pages to specify what they want to show in the header
// as title, and what the "back" action should be (if "back" should be shown). The
// <Header> component will then use the options from the deepest nested component.
//
// Multiple sibling components should not simultaneously render this component.
export const ArtemisHeaderOptions = ({ children, onBack, title }: ArtemisHeaderOptionsProps) => {
  const { setChildOptions } = useContext(HeaderContext);
  const [optionsFromMyChildren, setOptionsFromMyChildren] = useState<HeaderOptions[]>([]);

  const options = useMemo(() => (onBack || title ? { onBack, title } : null), [onBack, title]);

  const contextValue = useMemo(() => {
    return {
      setChildOptions: setOptionsFromMyChildren,
      options: options ? [options, ...optionsFromMyChildren] : optionsFromMyChildren,
    };
  }, [options, optionsFromMyChildren]);

  useFocusEffect(
    useCallback(() => {
      if (!options) {
        return;
      }

      setChildOptions([options, ...optionsFromMyChildren]);

      return () => {
        setChildOptions([]);
      };
    }, [options, optionsFromMyChildren, setChildOptions])
  );

  return <HeaderContext.Provider value={contextValue}>{children}</HeaderContext.Provider>;
};

// Return the most deeply nested option for each option field
export const useArtemisHeaderOptions = () => {
  const { options } = useContext(HeaderContext);

  const onBack = options.findLast(item => item.onBack)?.onBack;
  const title = options.findLast(item => item.title)?.title;

  return { onBack, title };
};

import React from 'react';
import { View } from 'react-native';

import { PulseProvider, SkeletonLoader, Text, useBrowserTypeMap, useTheme } from '@almond/ui';

import { themedStyles } from './styles';

export type SectionWithItemsProps = React.PropsWithChildren<{
  title: React.ReactNode;
  footer?: React.ReactNode;
  mode?: 'default' | 'accent';
  isLoading?: boolean;
  isCollapsed?: boolean;
}>;

export const SectionWithItems: React.FC<SectionWithItemsProps> = props => {
  const { children, title, footer, mode, isLoading, isCollapsed } = props;
  const [styles] = useTheme(themedStyles);
  const { isMobile } = useBrowserTypeMap();

  const collapsedStyles = isCollapsed ? { maxHeight: isMobile ? 300 : 280 } : null;

  const titleComponent = (() => {
    if (isLoading) {
      return <SkeletonLoader.RoundedRectangle width={220} height={16} roundness={4} />;
    }

    return typeof title === 'string' ? (
      <Text fontStyle="medium" size="xl" style={styles.title}>
        {title}
      </Text>
    ) : (
      title
    );
  })();

  return (
    <PulseProvider duration={1500}>
      <View style={[styles.container, mode === 'accent' && styles.containerAccent]}>
        <View style={[styles.content, footer ? styles.contentWithFooter : null, collapsedStyles]}>
          {titleComponent}
          {children}
        </View>
        {footer && <View style={styles.footer}>{footer}</View>}
      </View>
    </PulseProvider>
  );
};

import React from 'react';

import Head from 'expo-router/head';

type PatientPageTitleProps = {
  title?: string;
};

export const PatientPageTitle = (props: PatientPageTitleProps) => {
  return (
    <Head>
      <title>{props.title ? `${props.title} - ` : ''}Almond ObGyn</title>
    </Head>
  );
};

import React from 'react';
import { View } from 'react-native';

import { Button, Text, useBrowserTypeMap, useTheme } from '@almond/ui';

import { ReferButton } from '../ReferButton';

import { themedStyles } from './styles';

export const ReferralBanner = () => {
  const [styles] = useTheme(themedStyles);
  const { isMobile } = useBrowserTypeMap();

  return (
    <View style={styles.container} testID="Referral">
      <Text fontStyle="medium" testID="Title">
        {isMobile ? '💕 Refer a friend and earn $100' : '💕 Refer a friend and earn $100 towards your membership!'}
      </Text>
      <ReferButton
        renderButton={buttonProps => (
          <Button
            {...buttonProps}
            style={styles.button}
            labelStyle={styles.buttonLabel}
            fixedWidth={false}
            testID="ReferFriends"
          >
            {'Learn More'}
          </Button>
        )}
      />
    </View>
  );
};

import React from 'react';

import { IconButton, MaterialIcon, sizes, useTheme } from '@almond/ui';

import { themedStyles } from './styles';

import type { SendButtonProps } from 'stream-chat-react';

export const FileUploadIcon = () => {
  // size determined by trial and error
  return <MaterialIcon source="add" size={sizes.SPACING_XL} color="primary" />;
};

export const ActionsIcon = () => {
  // size determined by trial and error
  return <MaterialIcon source="more-vert" size={sizes.SPACING_L} color="primary" />;
};

export const SendButton: React.FC<SendButtonProps> = props => {
  const { style: propStyle = {} } = props;
  const [styles] = useTheme(themedStyles);

  // icon is 30px in designs. Due to margin within icon, need to use 34px to get
  // actual icon to be 30px
  return (
    <IconButton
      source="arrow-upward"
      color="background"
      onPress={props.sendMessage}
      isDisabled={props.disabled}
      style={[styles.sendButton, props.disabled && styles.sendButtonDisabled, propStyle as any]}
    />
  );
};

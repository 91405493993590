import React from 'react';
import { View } from 'react-native';

import { Text, useAdaptive, useBrowserTypeMap, useTheme } from '@almond/ui';
import { useSegments } from 'expo-router';
import { useChatContext } from 'stream-chat-react';

import { useChannelData } from '../../hooks';
import { LeaveChannelButton } from './LeaveChannelButton';
import { MarkAsUnreadButton } from './MarkAsUnreadButton';
import { StatusToggleButton } from './StatusToggleButton';

import { themedStyles } from './styles';

type ChannelHeaderProps = {
  showJoinInThread?: boolean;
};

export const ChannelHeader = (props: ChannelHeaderProps) => {
  const [styles] = useTheme(themedStyles);
  const { channel } = useChatContext();
  const segments = useSegments();
  const isPatient = segments[0] === '(patient)';
  const isTodoPage = segments.at(-1) === 'todos';
  const channelData = useChannelData(channel);
  const { isMobile } = useBrowserTypeMap();
  const containerStyle = useAdaptive(styles.container, styles.mobileContainer);

  if (isPatient && isMobile) {
    return;
  }

  const title = (() => {
    if (isPatient) return 'You & Almond Care Team';
    if (!channelData) return 'Patient conversation';

    return `${channelData.name} (${channelData.birthday})`;
  })();

  const renderButtons = (children: React.ReactNode) => {
    if (isMobile) {
      return <View style={styles.mobileButtonContainer}>{children}</View>;
    }

    return children;
  };

  return (
    <View style={containerStyle} testID="Channel-ChannelHeader">
      <Text size="m" style={styles.text}>
        {title}
      </Text>
      {renderButtons(
        <>
          {!isTodoPage && <MarkAsUnreadButton />}
          {!isTodoPage && <StatusToggleButton />}
          {props.showJoinInThread && !isTodoPage && <LeaveChannelButton />}
        </>
      )}
    </View>
  );
};

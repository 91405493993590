import { useCallback } from 'react';

import { useUnstableGlobalHref } from 'expo-router';

import { env } from '~env';

// TODO: Cover with unit tests.
export const useAddParamsIfLabLink = () => {
  const unstableGlobalHref = useUnstableGlobalHref();

  return useCallback(
    (href?: string) => {
      const bookUrlNoSsl = env.BOOK_URL.replace(/^https:\/\//, 'http://');

      if (!href || !(href.startsWith(env.BOOK_URL) || href.startsWith(bookUrlNoSsl))) {
        return href;
      }

      // Get path from URL that we now know to be the booking URL
      const { pathname, search } = new URL(href);

      if (!search && (pathname === '/lab' || pathname === '/selfservelab')) {
        const searchParams = new URLSearchParams();

        searchParams.append('require_auth', 'true');
        searchParams.append('artemis_redirect_url', `${window.location.origin}${unstableGlobalHref}`);

        return `${env.BOOK_URL}${pathname}?${searchParams.toString()}`;
      }

      return href;
    },
    [unstableGlobalHref]
  );
};

import React from 'react';

import { ErrorContainer } from '~/modules/logging';
import { AdminTodoPage, TodoPageWrapper } from '~/modules/todos';

export { ErrorContainer as ErrorBoundary };

export default () => (
  <TodoPageWrapper>
    <AdminTodoPage />
  </TodoPageWrapper>
);

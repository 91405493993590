/* eslint-disable react/no-unescaped-entities */

import React, { useMemo } from 'react';
import { View } from 'react-native';

import { LOCAL_DATE_SHORT_FORMAT, REMOTE_DATE_FORMAT } from '@almond/date-and-time';
import { Text } from '@almond/ui';
import dayjs from 'dayjs';

import { useAlmondApiQuery } from '~/modules/api';

import { CATEGORIES } from '../../config';
import { BaseTodoItem } from '../BaseTodoItem';
import { unstable_styles as cssUnstableStyles } from './DraftTodoItem.module.css';
import { ErrorIcon } from './ErrorIcon';
import { ValidationMessage } from './ValidationMessage';
import { unstable_styles as validationCssUnstableStyles } from './ValidationMessage.module.css';

import type { PendingTodoItem } from '../../types';
import type { ValidationErrorType } from '../../validations';
import type { TodoCtaButton } from '../BaseTodoItem';

type DraftTodoItemProps = {
  item: PendingTodoItem;
  isExpanded: boolean;
  onExpand: (isExpanded: boolean) => void;
  ctaButtons?: TodoCtaButton[];
  errors: ValidationErrorType | undefined;
};

export const DraftTodoItem = (props: DraftTodoItemProps) => {
  const { item, isExpanded, onExpand, ctaButtons, errors } = props;

  const category = useMemo(() => CATEGORIES.find(c => c.id === item.category), [item]);
  const dueDate = useMemo(
    () => item.dueDate && dayjs(item.dueDate, REMOTE_DATE_FORMAT).format(LOCAL_DATE_SHORT_FORMAT),
    [item.dueDate]
  );

  const parsedMarkdown = useAlmondApiQuery<'/markdown/', 'post'>(item.description ? '/markdown/' : null, {
    method: 'post',
    body: { text: item.description },
  });

  return (
    <View style={cssUnstableStyles.wrapper}>
      {errors && errors.issues.map(i => <ValidationMessage key={i.code} type="error" message={i.message} />)}
      {category && item.initialCategory && category.title.toLowerCase() !== item.initialCategory.toLowerCase() && (
        <ValidationMessage
          type="info"
          message={
            <>
              Category updated to "
              <Text size="m" fontStyle="medium" style={validationCssUnstableStyles.text}>
                {category.title}
              </Text>
              "
            </>
          }
        />
      )}
      <BaseTodoItem
        title={
          <>
            <Text style={cssUnstableStyles.newLabel} fontStyle="medium">
              {'NEW '}
            </Text>
            {item.title}
          </>
        }
        id={item.uuid}
        description={
          parsedMarkdown.data?.html ? (
            // eslint-disable-next-line @typescript-eslint/naming-convention
            <div dangerouslySetInnerHTML={{ __html: parsedMarkdown.data?.html }} />
          ) : (
            <div>
              <p>{item.description}</p>
            </div>
          )
        }
        dueDate={item.dueDate ? `Complete by ${dueDate}` : 'No due date'}
        leftIcon={errors && <ErrorIcon color="lightError" />}
        isExpanded={isExpanded}
        onExpand={onExpand}
        ctaButtons={ctaButtons}
        style={errors && cssUnstableStyles.invalid}
        dataSet={{ testclass: 'TodoItem', uuid: item.uuid }}
      />
    </View>
  );
};

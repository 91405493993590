import React, { memo, useRef, useState } from 'react';

import { useEvent } from '@almond/utils';
import { useChatContext } from 'stream-chat-react';

import {
  DraftAttachmentProvider,
  UploadModal,
  useAddAttachments,
  useDraftAttachmentContext,
} from '~/modules/documents';

import { useConversationMembers, useDocuments } from '../../hooks';
import { MessageInputFlat } from './MessageInputFlat';

import type { DocumentCreateIn } from '@almond/api-types';
import type { DraftAttachmentContextType } from '~/modules/documents';
import type { DraftAttachment } from '~/modules/documents/types';

export const MessageInputFlatWrapper = memo(() => {
  const { channel, client } = useChatContext('AlmondMessageInput');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const members = useConversationMembers(channel);
  const patientUuid = members.find(m => m.isPatient)?.patientUuid;

  const { addToDocuments } = useDocuments(patientUuid);
  const { getCompletedFileUploads, setAttachments, attachDocumentIds } = useDraftAttachmentContext();
  const pendingDraftAttachments = useRef<DraftAttachmentContextType>(null);
  const addAttachments = useAddAttachments(setAttachments);
  const addDraftAttachments = useAddAttachments(pendingDraftAttachments.current?.setAttachments);

  // TODO update this with permissions, as part of ENG-2737
  const shouldUseUploadModal = client.user?.role !== 'patient';

  const onUploadButtonClick = useEvent(async (files: File[] = []) => {
    if (shouldUseUploadModal) {
      addDraftAttachments(files, false);
      setIsModalVisible(true);
    } else if (files.length) {
      addAttachments(files, false);
    }
  });

  const onBeforeSendMessage = useEvent(async () => {
    let files: DraftAttachment[];

    try {
      files = await getCompletedFileUploads();
    } catch (e) {
      throw new Error('Make sure all files have successfully uploaded. Remove or retry those that failed');
    }

    const createDocuments = files
      .map((item): DocumentCreateIn | undefined => {
        if (!item.cdn?.cdnKey) {
          // This should never happen, so throw
          throw new Error(`Could not find the correct filename mapping for file ${item.cdn?.url}`);
        }

        return {
          category: item.isHealthRecord ? 'health_record' : 'media',
          filename: item.cdn.cdnKey,
          documentType: item.healthRecordType || undefined,
        };
      })
      .filter(<T,>(item: T): item is Exclude<T, null | undefined> => Boolean(item));

    const createdDocuments = await addToDocuments(createDocuments);

    await attachDocumentIds(createdDocuments);
  });

  return (
    <>
      <DraftAttachmentProvider ref={pendingDraftAttachments}>
        <UploadModal
          isVisible={isModalVisible}
          onApply={() => {
            setIsModalVisible(false);
            pendingDraftAttachments.current?.commitAttachments();
          }}
          onRequestClose={() => {
            setIsModalVisible(false);
            pendingDraftAttachments.current?.revertAttachments();
          }}
        />
      </DraftAttachmentProvider>
      <MessageInputFlat
        onUploadButtonClick={onUploadButtonClick}
        onBeforeSendMessage={onBeforeSendMessage}
        showModalOnUpload={shouldUseUploadModal}
      />
    </>
  );
});

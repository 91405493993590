import React from 'react';
import { View } from 'react-native';

import { ActivityIndicator, sizes, Text, useTheme } from '@almond/ui';
import { Redirect } from 'expo-router';
import { useChatContext } from 'stream-chat-react';

import { LocalImage } from '~/modules/ui';

import themedStyles from './styles';

const ElationNoIdSpecified: React.FC = () => {
  const [styles] = useTheme(themedStyles);
  const { client } = useChatContext();

  if (!client?.user?.role) {
    return <ActivityIndicator size={sizes.SPACING_M} />;
  }

  if (client?.user?.role === 'careguide' || client?.user?.role === 'provider') {
    return <Redirect href="/(admin)/(conversations)" />;
  }

  return (
    <View style={styles.container}>
      <LocalImage source="plant2" resizeMode="contain" style={styles.image} />
      <Text style={styles.title} fontStyle="bold" size="xl">
        {'Please choose a patient chart to view patient conversation'}
      </Text>
    </View>
  );
};

export default ElationNoIdSpecified;

import { getShadow, styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(theme => ({
  container: {
    flex: 1,
    flexDirection: 'row',
  },
  leftSidebar: {
    ...getShadow(),
    // For some reason, getShadow applies `zIndex: 4`, which we want to undo
    zIndex: 'initial' as any,

    alignItems: 'stretch',
    justifyContent: 'center',
    backgroundColor: theme.lightSecondaryBackground,
    width: 230,
  },
  leftSidebarMobile: {
    width: '100%',
  },
  leftSidebarMobileHidden: {
    width: 0,
  },
  higherZIndex: {
    zIndex: 1,
  },
}));

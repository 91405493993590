import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  item: {
    paddingVertical: sizes.SPACING_S,
    rowGap: sizes.SPACING_XS,
  },

  lastItem: {
    paddingBottom: 0,
  },

  itemTitle: {
    color: theme.darkText,
  },
}));

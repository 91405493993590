import React from 'react';

import { Link, Text } from '@almond/ui';

import { Section } from '~/modules/ui';
import { useBookUrl } from '~/modules/user';

import type { PatientDetailOut, SubscriptionStatusEnum } from '@almond/api-types';

export type MembershipProps = {
  data: PatientDetailOut | undefined;
};

const subscriptionStatusMap: Record<SubscriptionStatusEnum, string> = {
  active: 'Active',
  canceled: 'Canceled',
  inactive: 'Inactive',
  incomplete: 'Inactive',
  incomplete_expired: 'Inactive',
  past_due: 'Active',
  paused: 'Paused',
  trialing: 'Active',
  unpaid: 'Active',
};

export const Membership = (props: MembershipProps) => {
  const { data } = props;

  const bookUrl = useBookUrl({
    patientUuid: data?.uuid,
    profileUuid: data?.profile?.uuid,
    product: 'gynecology_membership',
  });

  const status = data?.profile?.subscription?.status;

  if (!status) {
    return null;
  }

  const right = (() => {
    if (['active', 'trialing'].includes(status)) {
      return <Link url="https://almondhealth.typeform.com/to/FMHEJJ4C">{'Cancel Membership'}</Link>;
    }

    if (['inactive', 'incomplete_expired'].includes(status)) {
      return (
        <Text>
          <Link url={bookUrl}>{'Become an Almond member today!'}</Link>
        </Text>
      );
    }

    return null;
  })();

  return (
    <Section title="Membership">
      <Text>
        {`Membership Status: ${subscriptionStatusMap[status]}`}
        {right && ' | '}
        {right}
      </Text>
    </Section>
  );
};

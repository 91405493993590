import React, { useMemo, useState } from 'react';
import { Modal, Pressable, View } from 'react-native';

import { Button, IconButton, ScrollView, Text, useTheme } from '@almond/ui';
import { Link } from 'expo-router';

import { useLogout } from '~/modules/api';
// Prevent circular dependency
import { useUnreadCount } from '~/modules/messaging/hooks';
import { ReferButton } from '~/modules/referral';
// Prevent circular dependency
import { useGetTodos } from '~/modules/todos/hooks/useGetTodos';
import { Header } from '~/modules/ui';
import { useBookUrl, useCurrentUser } from '~/modules/user';

import { usePathnameWithGroups } from '../../hooks';

import { themedStyles } from './styles';

export const MobileMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [styles] = useTheme(themedStyles);
  const generalBookUrl = useBookUrl({ category: 'general' });
  const currentPath = usePathnameWithGroups();
  const unreadCount = useUnreadCount();
  const logout = useLogout();
  const currentUser = useCurrentUser();
  const { active } = useGetTodos({ patientUuid: currentUser?.patientUuid, skipArchived: true });
  const unreadTodoCount = active?.filter(todo => !todo.readAt).length;

  const menuItems = useMemo(
    () => [
      { title: 'Home', pathname: '/(patient)' as const },
      { title: 'Book Visit', href: generalBookUrl },
      { title: 'To Do List', pathname: '/(patient)/todos' as const, showIndicator: unreadTodoCount > 0 },
      { title: 'Messages', pathname: '/(patient)/messages' as const, showIndicator: unreadCount > 0 },
      { title: 'Health Records', pathname: '/(patient)/health-records' as const },
      { title: 'Account Settings', pathname: '/(patient)/settings' as const },
    ],
    [generalBookUrl, unreadTodoCount, unreadCount]
  );

  return (
    <>
      <IconButton
        source="hamburger-menu"
        color="secondaryTextDark"
        onPress={() => {
          setIsOpen(true);
        }}
      />
      <Modal visible={isOpen} transparent>
        <Pressable onPress={() => setIsOpen(false)} style={styles.mobileMenuBackdrop} aria-hidden />
        <View style={styles.mobileMenu}>
          <Header
            title="almond"
            style={styles.mobileMenuHeader}
            left={<IconButton source="close" color="secondaryTextDark" onPress={() => setIsOpen(false)} />}
          />
          <ScrollView contentContainerStyle={styles.mobileMenuItems} role="navigation">
            <View>
              {menuItems.map(tab => {
                const currentPathStyle = tab.pathname === currentPath ? styles.mobileMenuItemActive : null;
                const text = tab.pathname ? (
                  <Link href={tab.pathname} style={styles.mobileMenuItem} onPress={() => setIsOpen(false)}>
                    <Text size="xxl" style={currentPathStyle}>
                      {tab?.title}
                    </Text>
                    {tab.showIndicator && <View style={styles.indicator} testID="UnreadIndicator" />}
                  </Link>
                ) : (
                  <Text size="xxl" href={tab.href} style={[styles.mobileMenuItem, currentPathStyle]}>
                    {tab?.title}
                  </Text>
                );

                return <View key={tab?.title}>{text}</View>;
              })}
            </View>

            <View>
              <View style={styles.mobileMenuReferContainer}>
                <Text size="xl" style={styles.mobileMenuReferText} fontStyle="book">
                  Share Almond with your community!
                </Text>
                <ReferButton
                  renderButton={buttonProps => (
                    <Button
                      {...buttonProps}
                      onPress={() => {
                        buttonProps.onPress();

                        // The referral modal takes ~0.1 seconds to open. To prevent a flash of the menu
                        // closing before the modal opens, wait for a small amount of time before closing the menu.
                        setTimeout(() => setIsOpen(false), 200);
                      }}
                      size="m"
                      fixedWidth={false}
                      style={styles.mobileMenuReferButton}
                    >
                      Earn a Free Membership
                    </Button>
                  )}
                />
              </View>
              <Pressable
                role="button"
                onPress={() => {
                  setIsOpen(false);
                  logout();
                }}
                style={styles.mobileMenuSignOut}
              >
                <Text size="xl" style={styles.mobileMenuSignOutText} fontStyle="book">
                  Sign Out
                </Text>
              </Pressable>
            </View>
          </ScrollView>
        </View>
      </Modal>
    </>
  );
};

import React from 'react';
import { View } from 'react-native';

import { useTheme } from '@almond/ui';
import { useGate } from 'statsig-react';
import { useChatContext } from 'stream-chat-react';

// Prevent circular dependency
import { useUnreadCount } from '~/modules/messaging/hooks';
// Prevent circular dependency
import { useGetTodos } from '~/modules/todos/hooks/useGetTodos';
import { isCurrentUserIncomplete, useCurrentUser } from '~/modules/user';

import { Tab } from './Tab';

import { themedStyles } from './styles';

import type { TabProps } from './Tab';

export const Tabs: React.FC = () => {
  const { value } = useGate('todos');
  const [styles] = useTheme(themedStyles);
  const unreadCount = useUnreadCount();
  const { client } = useChatContext();
  const { user } = client;
  const currentUser = useCurrentUser();
  const { active } = useGetTodos({ patientUuid: currentUser?.patientUuid, skipArchived: true });
  const unreadTodoCount = active?.filter(todo => !todo.readAt).length;

  // TODO: Add translations.
  const tabs = [
    { title: 'Home', href: '/(patient)' },
    value ? { title: 'To Do List', href: '/(patient)/todos', indicator: unreadTodoCount > 0 } : null,
    { title: 'Health Records', href: '/(patient)/health-records' },
    {
      title: 'Messages',
      href: '/(patient)/messages',
      indicator: unreadCount > 0,
      isDisabled: !!user?.role && isCurrentUserIncomplete(user.role),
    },
  ] satisfies (TabProps | null)[];

  return <View style={styles.tabs}>{tabs.map(tab => tab && <Tab key={tab.href} {...tab} />)}</View>;
};

import { useCallback, useEffect, useRef } from 'react';

import { getDeferred, useEvent } from '@almond/utils';

import { useUploadFile } from '../../hooks/useUploadFile';

import type { DraftAttachment } from '../../types';
import type { HealthRecordTypesEnum } from '@almond/api-types';
import type { Deferred } from '@almond/utils';
import type { Dispatch, SetStateAction } from 'react';

export const useUploadDraftAttachment = (
  enabled: boolean,
  patientUuid: string | undefined,
  attachments: DraftAttachment[],
  setAttachments: Dispatch<SetStateAction<DraftAttachment[]>>
) => {
  const uploadFile = useUploadFile(patientUuid);

  // Update a given attachment's CDN values in React state. Just a utility function
  // to use below, since we're doing it in 3 places.
  const updateStatus = useCallback(
    (attachment: DraftAttachment, newValues: DraftAttachment['cdn'], newDocumentType?: HealthRecordTypesEnum) => {
      setAttachments(existingFiles => {
        return existingFiles.map(existing => {
          if (existing.id === attachment.id) {
            return {
              ...existing,
              healthRecordType: newDocumentType ?? existing.healthRecordType,
              cdn: newValues,
            };
          }

          return existing;
        });
      });
    },
    [setAttachments]
  );

  // /////////////////////
  // Using deferreds, implement a way to "await" for
  // all uploads to finish, resolving to the attachments
  // list, but the up-to-date version after everything
  // finishes uploading
  // /////////////////////

  const deferreds = useRef<Deferred<DraftAttachment[]>[]>([]);

  useEffect(() => {
    if (!enabled) return;

    // Try to upload all files that have been added at this point
    attachments.map(async attachment => {
      if (attachment.cdn) {
        return;
      }

      updateStatus(attachment, { status: 'uploading' });

      try {
        const result = await uploadFile(attachment);

        updateStatus(
          attachment,
          {
            status: 'success',
            url: result.url,
            fileName: result.fileName,
            cdnKey: 'cdnKey' in result && typeof result.cdnKey === 'string' ? result.cdnKey : result.fileName,
          },
          result.documentType
        );
      } catch (e) {
        updateStatus(attachment, { status: 'error' });
      }
    });

    // If we're waiting for files to finish, and all files have
    // finished uploading, resolve the promises
    if (deferreds.current.length) {
      const isSuccess = attachments.every(attachment => attachment.cdn?.status === 'success');

      if (isSuccess) {
        deferreds.current.map(d => d.resolve(attachments));
      }

      const isError = attachments.some(attachment => attachment.cdn?.status === 'error');

      if (isError) {
        deferreds.current.map(d => d.reject(attachments));
      }

      if (isSuccess || isError) {
        deferreds.current = [];
      }
    }
  }, [attachments, uploadFile, setAttachments, enabled, updateStatus]);

  const getCompletedFileUploads = useEvent(() => {
    if (attachments.every(item => item.cdn?.status === 'success')) {
      return Promise.resolve(attachments);
    }

    if (attachments.some(item => item.cdn?.status === 'error')) {
      return Promise.reject(new Error('Upload failed'));
    }

    // At least one item is in progress, wait for it to finish
    const deferred = getDeferred<DraftAttachment[]>();

    deferreds.current.push(deferred);

    return deferred.promise;
  });

  const retryAttachmentUpload = (toRetry: DraftAttachment) => {
    // Clear upload status. The above useEffect will automatically
    // start trying to upload again
    updateStatus(toRetry, undefined);
  };

  if (enabled) {
    return { getCompletedFileUploads, retryAttachmentUpload };
  }

  return { getCompletedFileUploads: () => Promise.resolve([]), retryAttachmentUpload: () => undefined };
};

import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(() => ({
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    padding: sizes.SPACING_XS,
  },
}));

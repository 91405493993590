import React from 'react';
import { View } from 'react-native';

import { Link, Text, useTheme } from '@almond/ui';
import { Link as RouterLink } from 'expo-router';

import { themedStyles } from './styles';

import type { Href } from 'expo-router';

type ProgramItemInfo = {
  title: string;
  description: string;
  learnMoreHref: string;
  bookTitle: string;
} & ({ bookUrl: string } | { bookHref: Href });

export type ProgramItemProps = {
  item: ProgramItemInfo;
};

export const ProgramItem: React.FC<ProgramItemProps> = props => {
  const { item } = props;
  const [styles] = useTheme(themedStyles);

  return (
    <View style={styles.item}>
      <Text style={styles.itemTitle} fontStyle="medium">
        {item.title}
      </Text>
      <Text>{item.description}</Text>
      <Text>
        <Link url={item.learnMoreHref} size="s">
          {'Learn More'}
        </Link>
        {' | '}
        {'bookUrl' in item ? (
          <Link url={item.bookUrl} size="s">
            {item.bookTitle}
          </Link>
        ) : (
          <RouterLink href={item.bookHref} style={styles.link}>
            {item.bookTitle}
          </RouterLink>
        )}
      </Text>
    </View>
  );
};

import React, { useCallback } from 'react';

import { renderText } from 'stream-chat-react';

import { useAddParamsIfLabLink } from '../../hooks';

import type { UserResponse } from 'stream-chat';

const LinkWithLabLink = (props: React.AnchorHTMLAttributes<HTMLAnchorElement> & { node?: any }) => {
  const { node, ...restProps } = props;
  const addParamsIfLabLink = useAddParamsIfLabLink();

  return (
    // Component that renders a link, but if it's a link to a lab login it adds the query params
    <a {...restProps} href={addParamsIfLabLink(props.href)} />
  );
};

export const useCustomRenderText = (addParamsToLabLink: boolean) => {
  // Tell Stream's Markdown processor to use `<LinkWithLabLogin>`
  // instead of `<a>` to render links
  return useCallback(
    function customRenderText(text?: string, mentionedUsers?: UserResponse[]) {
      let textToRender = text;

      // We want a single newline in the text to appear as a full paragraph break.
      // Markdown requires 2 line breaks to make a full paragraph break, so double them up
      textToRender = textToRender?.replaceAll(/([^\n])\n([^\n])/g, '$1\n\n$2');

      return renderText(textToRender, mentionedUsers, {
        customMarkDownRenderers: {
          // If disabled, render <a> by passing 'a'
          a: addParamsToLabLink ? LinkWithLabLink : 'a',
        },
      });
    },
    [addParamsToLabLink]
  );
};

import React from 'react';

import { ActivityIndicator, Button, useTheme } from '@almond/ui';

import { useAlmondApiQuery } from '~/modules/api';
import { useBookUrl, useCurrentUser } from '~/modules/user';

import { themedStyles } from './styles';

import type { ButtonProps } from '@almond/ui';

type CompleteHistoryProps = ButtonProps & {
  product?: string;
  isHistoryComplete?: boolean;
};

export const CompleteHistory = ({ product, isHistoryComplete, ...props }: CompleteHistoryProps) => {
  const [styles] = useTheme(themedStyles);
  const currentUser = useCurrentUser();
  const { data, isLoading } = useAlmondApiQuery(
    currentUser?.patientUuid ? `/patients/${currentUser?.patientUuid as '{patient_uuid}'}/` : null
  );

  const historyUrl = useBookUrl(
    { patientUuid: data?.uuid, profileUuid: data?.profile?.uuid, product },
    '/complete_on_boarding'
  );

  if (isLoading) return <ActivityIndicator />;

  if (isHistoryComplete) return null;

  return (
    <Button
      hrefAttrs={{ target: '_blank' }}
      size="xs"
      href={historyUrl}
      {...props}
      fixedWidth={true}
      style={[styles.completeHistory, props.style]}
    >
      {'Complete Health History'}
    </Button>
  );
};

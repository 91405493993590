import React from 'react';
import { Text, View } from 'react-native';

import { useTheme } from '@almond/ui';

import { PatientPageTitle } from '~/modules/routing';

import themedStyles from './styles';

const Patient404: React.FC = () => {
  const [styles] = useTheme(themedStyles);

  return (
    <View style={styles.container}>
      <PatientPageTitle title="404" />
      <Text style={styles.title}>{`Sorry, we can't find that!`}</Text>
    </View>
  );
};

export default Patient404;

import React from 'react';
import { View } from 'react-native';

import { Text, useTheme } from '@almond/ui';

import { useAlmondApiQuery } from '~/modules/api';
import { useCurrentUser } from '~/modules/user';

import { useNextSteps } from '../../hooks';
import { CompleteHistory } from '../CompleteHistory';
import { SectionWithItems } from '../SectionWithItems';

import { themedStyles } from './styles';

export const NextSteps = () => {
  const [styles] = useTheme(themedStyles);
  const currentUser = useCurrentUser();
  const { data, isLoading } = useAlmondApiQuery(
    currentUser?.patientUuid ? `/patients/${currentUser?.patientUuid as '{patient_uuid}'}/` : null
  );
  const { isHistoryComplete, stepsByProgram, product, hasBaseline } = useNextSteps(data);

  if (isLoading) return null;

  // If a patient doesn't have an elation chart, they must've not completed the
  // sign up flow in Demi. So the dashboard should only allow them to book an
  // appointment (which should create the Elation patient).
  if (!data?.elationChart) return null;

  // Gyn membership members don't need to see the history steps
  // after they've already completed them.
  if (isHistoryComplete && !hasBaseline) return null;

  const { title, steps } = stepsByProgram;

  const titleComponent = (
    <Text style={styles.title} fontStyle="medium" size="xxl" testID="Title">
      {title}
    </Text>
  );

  return (
    <SectionWithItems title={titleComponent}>
      {steps.map((step, index) => (
        <View style={styles.stepContainer} key={step.title}>
          <View>
            <Text fontStyle="medium" size="xxxl" style={styles.stepNumber}>
              {index + 1}
            </Text>
          </View>
          <View style={styles.stepTextContainer}>
            <Text fontStyle="bold" size="l">
              {index === 0 && isHistoryComplete && '✅ '}
              {step.title}
            </Text>
            <Text fontStyle="book" size="m">
              {step.description}
            </Text>
            {index === 0 && (
              <CompleteHistory style={styles.completeHistory} product={product} isHistoryComplete={isHistoryComplete} />
            )}
          </View>
        </View>
      ))}
    </SectionWithItems>
  );
};

import React from 'react';

import { IconButton, sizes } from '@almond/ui';
import { useChannelActionContext, useMessageContext } from 'stream-chat-react';

export const ReplyButton = () => {
  const { setQuotedMessage } = useChannelActionContext('ReplyButton');
  const { message } = useMessageContext('ReplyButton');

  // Copied from
  // https://github.com/GetStream/stream-chat-react/blob/master/src/components/MessageActions/MessageActionsBox.tsx#L118
  const handleQuote = () => {
    setQuotedMessage(message);

    const elements = message.parent_id
      ? document.querySelectorAll('.str-chat__thread .str-chat__textarea__textarea')
      : document.getElementsByClassName('str-chat__textarea__textarea');
    const textarea = elements.item(0);

    if (textarea instanceof HTMLTextAreaElement) {
      textarea.focus();
    }
  };

  return (
    <div className="str-chat__message-light__actions str-chat__message-options">
      <IconButton source="reply-all" size={sizes.SPACING_M} onPress={handleQuote} />
    </div>
  );
};

import React, { useCallback, useEffect } from 'react';
import { View } from 'react-native';

import { NoticeModal, useTheme } from '@almond/ui';

import { useAlmondApiMutation } from '~/modules/api';
import { LocalImage } from '~/modules/ui';

import { themedStyles } from './styles';

import type { TodoDetailOut } from '@almond/api-types';
import type { NoticeModalProps } from '@almond/ui';

type TodoDeleteConfirmationProps = Pick<NoticeModalProps, 'isVisible'> & {
  item: TodoDetailOut | null;
  patientUuid: string | undefined;
  onRequestClose: (todoToDelete?: TodoDetailOut) => void;
};

export const TodoDeleteConfirmation = (props: TodoDeleteConfirmationProps) => {
  const { isVisible, item, patientUuid, onRequestClose } = props;
  const [styles] = useTheme(themedStyles);

  const { error, isMutating, reset, trigger } = useAlmondApiMutation(
    'delete',
    '/patients/{patient_uuid}/todos/{todo_uuid}/'
  );

  useEffect(() => {
    if (isVisible) {
      reset();
    }
  }, [isVisible, reset]);

  const onDelete = useCallback(async () => {
    if (!patientUuid || !item) {
      return;
    }

    try {
      const data = await trigger({
        todo_uuid: item.uuid,
        patient_uuid: patientUuid,
      });

      onRequestClose(data);
    } catch (e) {
      // Errors will be surfaced by the error value returned from useALmondApiMutation.
      // Catching here so the uncaught error doesn't propagate up to the top error handler
    }
  }, [item, onRequestClose, patientUuid, trigger]);

  return (
    <NoticeModal
      isVisible={!!item}
      image={
        <View style={[styles.modalImage, error && styles.modalImageError]}>
          <LocalImage resizeMode="contain" height="100%" width="100%" source={error ? 'error' : 'universe3'} />
        </View>
      }
      title={error ? 'There was an error deleting the To Do' : 'Are you sure you want to delete?'}
      subtitle={
        error
          ? 'Try again, or contact engineering if you continue to experience issues'
          : 'Deleting this item cannot be undone and will be shared in patient conversation.'
      }
      primaryButtonText="Delete To Do Item"
      secondaryButtonText="Nevermind"
      onPrimaryPress={onDelete}
      onSecondaryPress={() => onRequestClose()}
      onRequestClose={() => onRequestClose()}
      isLoading={isMutating}
    />
  );
};

import React from 'react';

import { useBrowserTypeMap } from '@almond/ui';
import { Slot, useRouter } from 'expo-router';
import { useChatContext } from 'stream-chat-react';

import { ErrorContainer } from '~/modules/logging';
import { AdminSidebar, MessagingLayout } from '~/modules/messaging';
import { ArtemisHeaderOptions } from '~/modules/ui';

export { ErrorContainer as ErrorBoundary };

export default () => {
  const router = useRouter();
  const { isMobile } = useBrowserTypeMap();
  const { channel } = useChatContext();

  return (
    <ArtemisHeaderOptions onBack={isMobile ? () => router.replace('/(admin)/(conversations)/') : null}>
      <MessagingLayout isLoading={!channel} sidebar={<AdminSidebar />}>
        <Slot />
      </MessagingLayout>
    </ArtemisHeaderOptions>
  );
};

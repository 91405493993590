import React from 'react';
import { Pressable } from 'react-native';

import { MaterialIcon, sizes, Text } from '@almond/ui';

import { unstable_styles as cssUnstableStyles } from './TodoItemButton.module.css';

import type { MaterialIconSource } from '@almond/ui';

export type TodoItemButtonProps = {
  icon?: MaterialIconSource;
  onPress: () => void;
  label: string;
};
export const TodoItemButton = (props: TodoItemButtonProps) => {
  return (
    <Pressable role="button" onPress={props.onPress} style={cssUnstableStyles.button}>
      {props.icon && (
        <MaterialIcon source={props.icon} style={cssUnstableStyles.icon} color="background" size={sizes.SPACING_M} />
      )}
      <Text size="s" style={cssUnstableStyles.buttonText}>
        {props.label}
      </Text>
    </Pressable>
  );
};

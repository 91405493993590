const forceDownload = (blobUrl: string, filename: string) => {
  const a = document.createElement('a');

  a.download = filename;
  a.href = blobUrl;
  // For Firefox https://stackoverflow.com/a/32226068
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const downloadFile = async (url: string, filename: string) => {
  const randomCacheValue = `random=${String(Math.random()).substring(3)}`;
  const joiner = url.includes('?') ? '&' : '?';
  const uniqueUrl = url + joiner + randomCacheValue;

  const response = await fetch(uniqueUrl, {
    credentials: 'include',
    mode: 'cors',
  });

  if (!response.ok) {
    throw new Error('Something went wrong');
  }

  const blob = await response.blob();

  const blobUrl = URL.createObjectURL(blob);

  forceDownload(blobUrl, filename);
  URL.revokeObjectURL(blobUrl);
};

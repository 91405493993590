import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Pressable, View } from 'react-native';

import { Accordion, MaterialIcon, Text, useTheme } from '@almond/ui';

import { CATEGORIES } from '../../config';
import { groupTodosIntoCategories, noCategory } from '../../utils';

import { themedStyles } from './styles';

import type { Ref } from 'react';

export { LoadingTodoList } from './LoadingTodoList';

// General TodoItem props, should be passible to <LiveTodoItem>
// and <DraftTodoItem>
type TodoItemProps<TodoItem extends { uuid: string; category?: string }> = {
  item: TodoItem;
  isExpanded: boolean;
  onExpand: (isExpanded: boolean) => void;
};

type TodoListProps<TodoItem extends { uuid: string; category?: string }> = {
  todos: readonly TodoItem[];
  futureTodos?: readonly TodoItem[];
  archivedTodos?: readonly TodoItem[];
  showAllCategories?: boolean;
  onClickAddTodo?: (category: (typeof CATEGORIES)[number]['id']) => void;
  renderItem: (props: TodoItemProps<TodoItem> & { ref?: Ref<View> }) => React.JSX.Element;
} & (
  | { expandedId: string | null; setExpandedId: (id: string | null) => void }
  | { expandedId?: never; setExpandedId?: never }
);

export const TodoList = <TodoItem extends { uuid: string; category?: string }>(props: TodoListProps<TodoItem>) => {
  const {
    todos,
    futureTodos,
    archivedTodos,
    showAllCategories,
    onClickAddTodo,
    renderItem,
    expandedId: externalExpandedId,
    setExpandedId: setExternalExpandedId,
  } = props;
  const [styles] = useTheme(themedStyles);

  // Only used if expandedId/setExpandedId are not set
  const [localExpandedId, setLocalExpandedId] = useState<string | null>(null);

  // If external ID is being set, it still may be falsy if there are none expanded. So branch on the setter existing
  const expandedItemId = setExternalExpandedId ? externalExpandedId : localExpandedId;
  const setExpandedId = setExternalExpandedId || setLocalExpandedId;
  const itemRefs = useRef<{ [uuid: string]: View }>({});

  const initialExpandedId = useRef(externalExpandedId);

  useEffect(() => {
    if (initialExpandedId.current) {
      const ele = itemRefs.current[initialExpandedId.current];

      if (ele instanceof HTMLElement) {
        ele.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, []);

  const groupedTodos = useMemo(() => groupTodosIntoCategories(todos), [todos]);

  // Calling this in 3 places throughout this component - extract into this reusable block
  const renderTodoItems = (todoItems: readonly TodoItem[]) =>
    todoItems.map(todo => (
      <Fragment key={todo.uuid}>
        {renderItem({
          ref: (ele: View) => {
            itemRefs.current[todo.uuid] = ele;
          },
          item: todo,
          isExpanded: expandedItemId === todo.uuid,
          onExpand: isExpanded => {
            if (isExpanded) {
              setExpandedId(todo.uuid);
            } else {
              setExpandedId(null);
            }
          },
        })}
      </Fragment>
    ));

  return (
    <View testID="TodoList" style={styles.content}>
      {groupedTodos[noCategory] && (
        <View style={styles.category}>
          <View style={styles.items}>{renderTodoItems(groupedTodos[noCategory])}</View>
        </View>
      )}
      {CATEGORIES.map(category => {
        const categoryTodos = groupedTodos[category.id] ?? [];

        if (!categoryTodos.length && !showAllCategories) {
          return null;
        }

        return (
          <View
            key={category.id}
            style={styles.category}
            // @ts-ignore
            dataSet={{ category: category.id }}
          >
            <View style={[styles.categoryTitle, categoryTodos.length ? styles.categoryTitleWithItems : null]}>
              <Text fontStyle="bold" size="l">
                {category.title}
              </Text>
              {onClickAddTodo && (
                <Pressable
                  role="button"
                  onPress={() => onClickAddTodo(category.id)}
                  aria-label={`Create new ${category.title} To Do`}
                  style={styles.circleButton}
                >
                  <MaterialIcon source="add" size={20} color="lightSecondaryBackground" />
                </Pressable>
              )}
            </View>
            <View style={styles.items}>{renderTodoItems(categoryTodos)}</View>
          </View>
        );
      })}
      {!!futureTodos?.length && (
        <Accordion
          style={[styles.category, styles.expandableCategoryTitle]}
          title={
            <View style={[styles.categoryTitle, styles.categoryTitleWithItems]}>
              <Text fontStyle="bold" size="l">
                Future
              </Text>
            </View>
          }
        >
          <View
            style={styles.items}
            // @ts-ignore
            dataSet={{ category: 'future' }}
          >
            {renderTodoItems(futureTodos)}
          </View>
        </Accordion>
      )}
      {!!archivedTodos?.length && (
        <Accordion
          style={[styles.category, styles.expandableCategoryTitle]}
          title={
            <View style={[styles.categoryTitle, styles.categoryTitleWithItems]}>
              <Text fontStyle="bold" size="l">
                Archive
              </Text>
            </View>
          }
        >
          <View
            style={styles.items}
            // @ts-ignore
            dataSet={{ category: 'archived' }}
          >
            {renderTodoItems(archivedTodos)}
          </View>
        </Accordion>
      )}
    </View>
  );
};

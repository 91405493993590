import type { Channel, ChannelSort } from 'stream-chat';

export const streamChannelSort = (channels: Channel[], sort: ChannelSort) => {
  const sortArr = Array.isArray(sort) ? sort : [sort];

  return [...channels].sort((channelA, channelB) => {
    for (let i = 0; i < sortArr.length; i += 1) {
      const sortKey = Object.keys(sortArr[i])[0];
      const sortDirection = sortArr[i][sortKey as keyof (typeof sortArr)[0]];

      if (sortDirection !== 1 && sortDirection !== -1) {
        throw new Error(`Sort Direction must be 1 or -1, instead it was ${sortDirection}`);
      }

      if (sortKey === 'has_unread') {
        const aUnread = channelA.countUnread();
        const bUnread = channelB.countUnread();

        if (aUnread && !bUnread) {
          return sortDirection;
        }

        if (!aUnread && bUnread) {
          return -1 * sortDirection;
        }
      } else if (sortKey === 'last_message_at') {
        const aLastMessage = Date.parse(channelA.data?.last_message_at as string) || 0;
        const bLastMessage = Date.parse(channelB.data?.last_message_at as string) || 0;

        if (aLastMessage > bLastMessage) {
          return sortDirection;
        }

        if (bLastMessage > aLastMessage) {
          return -1 * sortDirection;
        }
      } else {
        throw new Error(`Unsupported sort key ${sortKey}`);
      }
    }

    return 0;
  });
};

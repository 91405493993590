import { useAlmondApiQuery } from '~/modules/api';
import { useCurrentUser } from '~/modules/user';

export const useInputBanner = (patientUuid: string | null): { inputBanner: string | null } => {
  const currentUser = useCurrentUser();
  const { data } = useAlmondApiQuery(patientUuid ? `/patients/${patientUuid as '{patient_uuid}'}/` : null);

  const isAdmin = currentUser?.isAdmin;
  const isVirtualMember = data?.profile?.subscription?.membershipType === 'virtual_membership';

  if (isAdmin && !isVirtualMember) {
    return { inputBanner: null };
  }

  const name = data?.profile?.preferredFirstName ? data?.profile?.preferredFirstName : data?.profile?.firstName;
  const virtualMessage = `${name} is a virtual member.`;
  const message = isAdmin && isVirtualMember ? virtualMessage : 'We typically respond within 1 business day.';

  return { inputBanner: message };
};

import React from 'react';
import { Image, View } from 'react-native';

import { IconButton, Modal, SENTRY_UNMASK, Text, useBrowserType, useTheme } from '@almond/ui';

import { downloadFile, getFileType } from '../../utils';

import { themedStyles } from './styles';

import type { ExtendedDocumentOut } from '../../types';
import type { FC } from 'react';

type DocumentViewerProps = {
  document: Pick<ExtendedDocumentOut, 'filename' | 'fileLocation'> | null;
  onClose: () => void;
};

export const DocumentViewer: FC<DocumentViewerProps> = props => {
  const [styles] = useTheme(themedStyles);
  const browserType = useBrowserType();
  const isMobile = browserType === 'mobile';

  if (!props.document) return <Modal isVisible={false} onRequestClose={props.onClose} containerStyle={styles.modal} />;

  const fileType = getFileType(props.document.filename);

  const onDownload = async () => {
    if (!props.document) return;

    try {
      await downloadFile(props.document.fileLocation, props.document.filename);
    } catch (error) {
      // TODO toast notification
      // eslint-disable-next-line no-console
      console.error(error);
      // In the meantime, re-throw so Sentry sees an uncaught error and we're alerted
      throw error;
    }
  };

  return (
    <Modal
      isVisible={!!props.document}
      onRequestClose={props.onClose}
      containerStyle={[styles.modal, isMobile && styles.mobileModal]}
      contentContainerStyle={styles.modalContent}
      testID="DocumentViewer"
    >
      <View style={[styles.header, isMobile && styles.mobileHeader, SENTRY_UNMASK]}>
        <IconButton source="close" onPress={props.onClose} accessibilityLabel="Close" testID="Modal-Close" />
        <Text fontStyle="bold" size="xxxl" style={styles.logo}>
          almond
        </Text>
        <IconButton source="download-2" onPress={onDownload} accessibilityLabel="Download" />
      </View>
      {fileType === 'image' && (
        <Image
          source={{ uri: props.document.fileLocation }}
          alt={props.document.filename}
          style={styles.image}
          resizeMode="contain"
          testID="Document"
        />
      )}
    </Modal>
  );
};

import React from 'react';

import { Button, useBrowserTypeMap, useTheme } from '@almond/ui';
import { useGlobalSearchParams, useRouter } from 'expo-router';
import { useChatContext } from 'stream-chat-react';

import { useAlmondApiQuery } from '~/modules/api';
import { useChannel } from '~/modules/messaging/hooks';
import { Error } from '~/modules/ui';
import { getChannelPatient, userIdToConversationId } from '~/modules/user';

import { ReferralModal } from '../ReferralModal';

import { themedStyles } from './styles';

import type { ButtonProps } from '@almond/ui';

type ReferButtonProps = {
  renderButton?: (
    props: Pick<ButtonProps, 'isLoading' | 'isDisabled'> & {
      isVisible: boolean;
      onPress: () => void;
    }
  ) => React.ReactNode;
};

export const ReferButton = (props: ReferButtonProps) => {
  const [styles] = useTheme(themedStyles);
  const router = useRouter();
  const searchParams = useGlobalSearchParams();
  const isVisible = searchParams.referral === 'true';
  const { client } = useChatContext();
  const conversationId = userIdToConversationId(client.user?.id);
  const { channel } = useChannel(conversationId, true);
  const patientUuid = getChannelPatient(channel)?.patient_uuid as string | null;
  const { data, error, isLoading } = useAlmondApiQuery(
    patientUuid ? `/patients/${patientUuid as '{patient_uuid}'}/` : null,
    {
      // This combination is relevant to useSWRImmutable.
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  const { isMobile } = useBrowserTypeMap();

  const renderButton =
    props.renderButton ??
    (buttonProps => (
      <Button
        {...buttonProps}
        style={[styles.button, isMobile && styles.buttonMobile]}
        size="s"
        fixedWidth={false}
        testID="ReferFriends"
      >
        {'Refer friends'}
      </Button>
    ));

  if (error) return <Error error="Error loading referral code. Please refresh the page." />;

  return (
    <>
      {renderButton({
        isVisible,
        isLoading,
        isDisabled: isLoading,
        onPress: () => router.setParams({ referral: 'true' }),
      })}
      <ReferralModal
        isVisible={isVisible}
        onRequestClose={() => router.setParams({ referral: undefined })}
        referralCode={data?.referralCode}
      />
    </>
  );
};

import React, { type FC, useEffect, useState } from 'react';

import { SmallPreview } from './SmallPreview';

import type { DraftAttachment } from '../../types';
import type { SmallPreviewProps } from './SmallPreview';
import type { PressableProps, ViewProps } from 'react-native';

const emptyFile = new File([], '');

type SmallPreviewDraftAttachmentProps = {
  item: DraftAttachment;
  onDelete?: PressableProps['onPress'];
  role?: ViewProps['role'];
} & Omit<SmallPreviewProps, 'fileName' | 'fileSize' | 'fileLocation'>;

export const SmallPreviewDraftAttachment: FC<SmallPreviewDraftAttachmentProps> = props => {
  const { item, ...restProps } = props;

  const [blobUrl, setBlobUrl] = useState<string | null>(null);

  const fileSize = 'file' in item ? item.file.size : 0;
  let fileName: string;

  if ('file' in item) {
    fileName = item.file.name;
  } else if (item.cdn?.fileName) {
    fileName = item.cdn.fileName;
  } else if (restProps.isError) {
    fileName = 'Error attaching file.pdf';
  } else {
    // Assume all Elation documents are PDFs. Setting this to be the filename causes
    // the preview to a) detect a PDF type, so it shows the PDF icon, and b) show "Loading..."
    // as the filename in the preview.
    fileName = 'Loading....pdf';
  }

  const itemFile = 'file' in item ? item.file : emptyFile;

  // Even if a CDN URL exists, keep the object URL in memory. This way,
  // the "loading" background image doesn't become a 404 in the moment after
  // the CDN URL exists but before it's been prefetched.
  useEffect(() => {
    const blobUrlTemp = URL.createObjectURL(itemFile);

    setBlobUrl(blobUrlTemp);

    return () => URL.revokeObjectURL(blobUrlTemp);
  }, [itemFile]);

  return (
    <SmallPreview fileName={fileName} fileSize={fileSize} fileLocation={item.cdn?.url || blobUrl} {...restProps} />
  );
};
